import React, { Fragment, useEffect, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames'
import moment from 'moment';

import {
    Input,
    TableView,
    Loading
} from 'components';

import { useLogManager } from 'config/hooks';
import { FormatNumberToCurrency, FormatDate, ShowErrorNotification } from 'config/helpers/Utils';

import {
    creditsListGetCredits,
    clearRedux
} from 'config/redux/actions';

const CreditsList = (props) => {
    const { canceled, className } = props;
    const dispatch = useDispatch();
    const { messages } = useIntl();

    const tableView = TableView.useTableView();
    const logManager = useLogManager();
    const { data, loading, error } = useSelector(state => state.creditsListRedux);

    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        loadData(canceled);
    }, [canceled]);

    useEffect(() => {
        if (error) {
            ShowErrorNotification(messages['errorMessage']);
            clearRedux("CREDITS_LIST", { error: false, response: [] });
        }
    }, [error]);

    const loadData = useCallback(() => {
        dispatch(creditsListGetCredits({ canceled: canceled ? true : false }));
    });

    const columns = useMemo(() => [
        {
            title: messages["credits_list_credit_number"],
            key: "Pagare",
            sortValue: (row) => {
                return parseInt(row?.Pagare);
            }
        },
        {
            title: messages["credits_list_date_purchase"],
            key: "FechaCompromiso",
            render: (row) => {
                return (
                    <div className='text-left'>
                        <span>{FormatDate(row?.FechaCompromiso, "ll")}</span>
                    </div>
                );
            },
            exportValue: (row) => {
                return FormatDate(row?.FechaCompromiso, "ll");
            },
            sortValue: (row) => {
                return moment(row?.FechaCompromiso).unix();
            }
        },
        {
            title: messages["credits_list_purchase_value"],
            key: "ValorCompromiso",
            headerStyle: {
                minWidth: 100
            },
            headerClass: "text-right",
            render: (row) => {
                return (
                    <div className='text-right'>
                        <span>{FormatNumberToCurrency(row?.ValorCompromiso || 0)}</span>
                    </div>
                );
            },
            exportValue: (row) => {
                return FormatNumberToCurrency(row?.ValorCompromiso || 0);
            },
            sortValue: (row) => {
                return parseInt(row?.ValorCompromiso);
            }
        },
        {
            title: messages["credits_list_purchase_balance"],
            key: "Saldo",
            headerClass: "text-right",
            headerStyle: {
                minWidth: 100
            },
            render: (row) => {
                return (
                    <div className='text-right'>
                        <span>{FormatNumberToCurrency(row?.Saldo || 0)}</span>
                    </div>
                );
            },
            exportValue: (row) => {
                return FormatNumberToCurrency(row?.Saldo || 0);
            },
            sortValue: (row) => {
                return parseInt(row?.Saldo);
            }
        },
        {
            title: messages["credits_list_purchase_term"],
            key: "Plazo",
            headerClass: "text-right",
            render: (row) => {
                return (
                    <div className='text-right'>
                        <span>{row?.Plazo}</span>
                    </div>
                );
            },
            sortValue: (row) => {
                return parseInt(row?.Plazo);
            }
        },
        {
            title: messages["credits_list_brand"],
            key: "Marca",
            headerClass: "text-left",
            render: (row) => {
                return (
                    <div className='text-left'>
                        <span>{row?.Marca}</span>
                    </div>
                );
            }
        },
        {
            title: messages["credits_list_purchase_place"],
            key: "PuntoVenta",
            headerClass: "text-left",
            render: (row) => {
                return (
                    <div className='text-left'>
                        <span>{row?.PuntoVenta}</span>
                    </div>
                );
            }
        },
        // {
        //     render: (row) => {
        //         return (
        //             <div className='text-right btn-link'>
        //                 {messages["credits_list_purchase_details"]}
        //             </div>
        //         )
        //     }
        // }
    ]);

    let title = canceled ? messages['credits_list_canceled_credits'] : messages['credits_list_active_credits'];

    return (
        <Fragment>
            <div className={cx("container-fluid bg-white", className || "")} >
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12">
                                <Loading loading={loading}>
                                    <div className="card m-0">
                                        <div className="card-header">
                                            <div className="row m-0 p-0 all-width">
                                                <div className="col-sm-12 col-md-6 m-0 p-0">
                                                    <h3 className="fw-bold text-primary mb-3">
                                                        {title}
                                                    </h3>
                                                </div>

                                                <div className="col-sm-12 col-md-6 m-0 p-0">
                                                    <div className='d-flex justify-content-xs-center justify-content-sm-center justify-content-md-end'>
                                                        <button
                                                            className="btn btn-xs btn-primary light btn-rounded me-2"
                                                            onClick={() => tableView.exportPdf()}
                                                        >
                                                            {messages['print']}
                                                        </button>

                                                        <Input
                                                            placeholder={messages['search']}
                                                            className="input-rounded pe-5"
                                                            icon="fas fa-search"
                                                            value={searchText}
                                                            onChange={(value) => {
                                                                setSearchText(value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <TableView
                                                    ref={tableView.ref}
                                                    columns={columns}
                                                    height="calc(100vh - 370px)"
                                                    items={data || []}
                                                    searchText={searchText}
                                                    name={`Créditos-${canceled ? "cancelados" : "vigentes"}`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Loading>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
};

export default CreditsList;