import { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

import cx from 'classnames'
import { Dropdown } from 'react-bootstrap'
import { useDropdownCustomToggle } from 'config/hooks'
import { IsMobile } from 'config/helpers/Utils';
import { endpoints } from 'config/constants';

import ProfileUpdatePassword from 'modules/profile/components/updatePassword';

import {
    profileOpenEditPassword,
    changeThemeConfig,
    logout
} from 'config/redux/actions';

const Header = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { messages } = useIntl()
    const DropdownToggle = useDropdownCustomToggle()

    const user = useSelector(state => state.authRedux.user)
    const isMenuActive = useSelector(state => state.themeRedux.isMenuActive)


    return (
        <Fragment>
            <div className="nav-header">
                <a href="/app" className="brand-logo">
                    <img className="logo-abbr" src="/assets/images/credi10-white.png" alt="" />
                    <img className="logo-compact" src="/assets/images/credi10-white.png" alt="" />
                    <img className="brand-title" src="/assets/images/credi10-white.png" alt="" />
                </a>

                <div className="nav-control">
                    <div className={cx("hamburger", isMenuActive && "is-active")} onClick={() => {
                        dispatch(changeThemeConfig({ isMenuActive: !isMenuActive }));
                    }}>
                        <span className="line"></span><span className="line"></span><span className="line"></span>
                    </div>
                </div>
            </div>

            <div className="header bg-white">
                <div className="header-content">
                    <nav className="navbar navbar-expand">
                        <div className="collapse navbar-collapse justify-content-between">
                            <div className="header-left">
                                <div className="dashboard_bar">
                                    {
                                        !IsMobile() &&
                                        <a href={`${endpoints?.CREDI10_PAYMENT_LINK}`} target="_blank" className='btn btn-md btn-rounded btn-primary'>
                                            {messages['header_pay_credit']}
                                            <i className='ms-2 fs-14 fas fa-money-bill-alt text-white' />
                                        </a>
                                    }
                                </div>
                            </div>

                            <ul className="navbar-nav header-right">
                                <li>
                                    <Dropdown className='nav-item'>
                                        <DropdownToggle className="nav-item dropdown header-profile" variant="" align={{ sm: 'end' }}>
                                            <a className="nav-link" >
                                                <div className="text-primary header-info d-flex align-items-center justify-content-start">
                                                    <div
                                                        className="text-primary fw-normal text-capitalize fs-5"
                                                        style={
                                                            IsMobile() ?
                                                                {
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    maxWidth: 120,
                                                                    whiteSpace: "nowrap"
                                                                } :
                                                                {}
                                                        }
                                                    >
                                                        {user?.name}
                                                    </div>
                                                    <span className="text-primary far fa-user ms-3 fs-3"></span>
                                                </div>
                                            </a>
                                        </DropdownToggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className='dropdown-item ai-icon'>
                                                <span 
                                                    className="ms-2"
                                                    onClick={() => {
                                                        dispatch(profileOpenEditPassword(true));
                                                    }}
                                                >
                                                    {messages["header_change_password"]}
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item className='dropdown-item ai-icon' onClick={() => dispatch(logout(navigate))}>
                                                <span className="ms-2">{messages["header_logout"]}</span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>

            <ProfileUpdatePassword />
        </Fragment>
    )
}

export default Header;