import {
    STATEMENT_GET_STATEMENT,
    STATEMENT_GET_STATEMENT_RESULT,
    CLEAR_REDUX,
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    error: false,
    data: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case STATEMENT_GET_STATEMENT:
            return { ...state, loading: true, data: null, error: false };

        case STATEMENT_GET_STATEMENT_RESULT:
            return { ...state, loading: false, error: action?.payload?.error || false, data: action?.payload?.response };

        case CLEAR_REDUX:
            return ["", "STATEMENT_LIST"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state
        default:
            return state;
    }
};
