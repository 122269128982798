//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';
import moment from 'moment';
import { ExceptionManager } from 'config/helpers/Logging';

//action types
import {
    MAIN_DASHBOARD_GET_DATA_PAYMENTS,
    MAIN_DASHBOARD_GET_LIQUIDATION
} from 'config/redux/actionTypes';

//actions
import {
    mainDashboardGetDataPaymentsResult,
    mainDashboardGetLiquidationResult
} from 'config/redux/actions';

const route = 'modules/main/redux/saga';

function* mainDashboardGetDataPaymentsRequest() {
    yield takeEvery(MAIN_DASHBOARD_GET_DATA_PAYMENTS, function* () {
        try {
            const response = yield call(
                clientQuery,
                `query getPaymentsHistory {
                    data: getPaymentsHistory
                }`,
                {},
                endpoints.GRAPHQL_GENERAL
            );

            if (!response?.data) {
                throw Error("Ha ocurrido un error, por favor intente de nuevo");
            }

            const monthsAgo = moment().subtract(11, 'months').unix();
            const { data } = response;

            let orderedData = [...data].sort((a, b) => {
                const itemA = moment(a?.FechaPago).unix();
                const itemB = moment(b?.FechaPago).unix();
        
                if (itemA > itemB) {
                    return -1;
                }
        
                if (itemA < itemB) {
                    return 1;
                }
        
                return 0;
            });

            let newData = [];

            //Get the last 12 months
            for (let i = 0; i < orderedData?.length; i++) {
                let item = orderedData[i];
                let unixdate = moment(item?.FechaPago).unix();
                if (unixdate > monthsAgo) {
                    newData.push(item);
                }
            }

            yield put(mainDashboardGetDataPaymentsResult(newData || [], false));
        } catch (exc) {
            ExceptionManager(exc, route, 'mainDashboardGetDataPaymentsRequest');
            yield put(mainDashboardGetDataPaymentsResult(null, true));
        }
    });
}

function* mmainDashboardGetLiquidationRequest() {
    yield takeEvery(MAIN_DASHBOARD_GET_DATA_PAYMENTS, function* () {
        try {

            const response = yield call(
                clientQuery,
                `query getClientLiquidation {
                    data: getClientLiquidation
                }`,
                {},
                endpoints.GRAPHQL_GENERAL
            );

            if (!response?.data) {
                throw Error("Ha ocurrido un error!");
            }

            yield put(mainDashboardGetLiquidationResult(response?.data, false));
        } catch (exc) {
            ExceptionManager(exc, route, 'mainDashboardGetDataPaymentsRequest');
            yield put(mainDashboardGetLiquidationResult(null, false));
        }
    });
}

//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(mainDashboardGetDataPaymentsRequest),
        fork(mmainDashboardGetLiquidationRequest),
    ]);
}
