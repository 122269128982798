import {
    //TYPES
    UPDATE_PASSWORD_SAVE,
    UPDATE_PASSWORD_SAVE_RESULT,
    CLEAR_REDUX,
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    result: null,
    error: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case UPDATE_PASSWORD_SAVE:
            return { ...state, loading: true, error: null, result: null }

        case UPDATE_PASSWORD_SAVE_RESULT:
            console.log("action.payload", action.payload);
            return { ...state, loading: false, result: action.payload.result, error: action.payload.error }

        case CLEAR_REDUX:
            return ["", "UPDATE_PASSWORD"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
