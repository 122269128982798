import {
    PROFILE_OPEN_CLOSE_UPDATE_PASSWORD,
    PROFILE_UPDATE_PASSWORD,
    PROFILE_UPDATE_PASSWORD_RESULT,
    CLEAR_REDUX,
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    result: null,
    error: null,
    open: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case PROFILE_OPEN_CLOSE_UPDATE_PASSWORD:
            return { ...INIT_STATE, open: action?.payload?.open };

        case PROFILE_UPDATE_PASSWORD:
            return { ...state, loading: true, result: null, error: null };

        case PROFILE_UPDATE_PASSWORD_RESULT:
            return { ...state, loading: false, result: action.payload.result, error: action.payload.error || false };

        case CLEAR_REDUX:
            return ["", "PROFILE_UPDATE_PASSWORD"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
