import {
    MAIN_DASHBOARD_GET_DATA_PAYMENTS,
    MAIN_DASHBOARD_GET_DATA_PAYMENTS_RESULT,
    MAIN_DASHBOARD_GET_LIQUIDATION,
    MAIN_DASHBOARD_GET_LIQUIDATION_RESULT,
    CLEAR_REDUX,
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
    loadingPayments: false,
    error: false,
    dataPayments: [],
    loadingLiquidation: false,
    liquidationResult: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case MAIN_DASHBOARD_GET_DATA_PAYMENTS:
            return { ...state, loadingPayments: true, dataPayments: [], error: false };

        case MAIN_DASHBOARD_GET_DATA_PAYMENTS_RESULT:
            return { ...state, loadingPayments: false, error: action?.payload?.error || false, dataPayments: action?.payload?.response };

        case MAIN_DASHBOARD_GET_LIQUIDATION:
            return { ...state, loadingLiquidation: true, liquidationResult: null, error: false };

        case MAIN_DASHBOARD_GET_LIQUIDATION_RESULT:
            return { ...state, loadingLiquidation: false, error: action?.payload?.error || false, liquidationResult: action?.payload?.response };

        case CLEAR_REDUX:
            return ["", "MAIN_DASHBOARD"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
