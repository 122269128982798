import {
    PQRS_CREATE,
    PQRS_CREATE_RESULT,
    CLEAR_REDUX,
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    error: false,
    response: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case PQRS_CREATE:
            return { ...INIT_STATE, loading: true, error: false };

        case PQRS_CREATE_RESULT:
            return { ...state, loading: false, error: action?.payload?.error || false, response: action?.payload?.response };

        case CLEAR_REDUX:
            return ["", "PQRS"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
