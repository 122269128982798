import cx from 'classnames'

export const Checkbox = ({ id, value, onChange, label, className }) => {

    return (
        <div className={cx("form-check", className)}>
            <input id={id} type="checkbox" className="form-check-input" checked={value || false} onChange={(e) => onChange && onChange(e.target.checked)} />
            <label 
                className="form-check-label" 
                htmlFor={id}
                style={{
                    paddingTop: 2
                }}
            >
                {label}
            </label>
        </div>
    )
}