import { Fragment } from "react"
import cx from 'classnames'

export const Input = (props) => {
    const {
        id,
        value,
        icon,
        onClickIcon,
        onChange,
        placeholder,
        className,
        autoComplete,
        type,
        disabled,
        loading,
        readOnly,
        maxLength,
        rows
    } = props;

    if (type === "textarea") {
        return (
            <div className="input-container">
                <textarea
                    id={id}
                    placeholder={placeholder}
                    className={cx("form-control", className)}
                    value={value || ""}
                    type={type || "text"}
                    autoComplete={autoComplete || "off"}
                    onChange={(e) => {
                        onChange(e.target.value)
                    }}
                    disabled={disabled}
                    readOnly={readOnly || false}
                    maxLength={maxLength || 50}
                    rows={rows || 5}
                    style={{
                        height: 70,
                        borderRadius: 20,
                        resize: "none"
                    }}
                />
            </div>
        )
    }

    return (
        <div className="input-container">
            <input
                id={id}
                placeholder={placeholder}
                className={cx("form-control", className)}
                value={value || ""}
                type={type || "text"}
                autoComplete={autoComplete || "off"}
                onChange={(e) => {
                    let value = e?.target?.value || "";
                    if (type === "number") {
                        value = value.slice(0, maxLength || 50);
                    }

                    onChange(value);
                }}
                disabled={disabled}
                //loading={loading || false} 
                readOnly={readOnly || false}
                maxLength={maxLength || 50}
            />

            {
                icon &&
                <i
                    onClick={() => {
                        if (onClickIcon) onClickIcon();
                    }}
                    className={cx("form-control-icon", icon, (onClickIcon ? "cursor-pointer" : ""))}
                >

                </i>
            }
        </div>
    )
}