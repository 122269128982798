import {
    //TYPES
    LOGIN_LOGIN,
    LOGIN_FACEBOOK,
    LOGIN_GOOGLE,
    LOGIN_LOGIN_RESULT,
    LOGIN_VALIDATE,
    LOGIN_VALIDATE_RESULT
} from 'config/redux/actionTypes';

export const loginSingIn = (username, password, navigate) => ({
    type: LOGIN_LOGIN,
    payload: { username, password, navigate },
});

export const loginValidate = (username) => ({
    type: LOGIN_VALIDATE,
    payload: { username },
});

export const loginValidateResult = (response) => ({
    type: LOGIN_VALIDATE_RESULT,
    payload: { response },
});

export const loginFacebook = (id, token, navigate) => ({
    type: LOGIN_FACEBOOK,
    payload: { id, token, navigate },
});

export const loginGoogle = (token, navigate) => ({
    type: LOGIN_GOOGLE,
    payload: { token, navigate },
});


export const loginSingInResult = (result) => ({
    type: LOGIN_LOGIN_RESULT,
    payload: result,
});
