import {
    UPDATE_PASSWORD_SAVE,
    UPDATE_PASSWORD_SAVE_RESULT
} from 'config/redux/actionTypes';

export const updatePasswordSave = (username, code, password) => ({
    type: UPDATE_PASSWORD_SAVE,
    payload: { username, code, password },
});

export const updatePasswordSaveResult = (result, error) => ({
    type: UPDATE_PASSWORD_SAVE_RESULT,
    payload: { result, error },
});