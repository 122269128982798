//imports
import { all } from 'redux-saga/effects';

import loginSagas from '../../views/login/redux/sagas';
import forgotPasswordSagas from '../../views/forgotPassword/redux/sagas';
import updatePasswordSagas from '../../views/updatePassword/redux/sagas';

//merge sagas
export default function* rootSaga() {
    yield all([
        loginSagas(),
        forgotPasswordSagas(),
        updatePasswordSagas()
    ]);
}
