import {
    //TYPES
    FORGOT_PASSWORD_VALIDATE,
    FORGOT_PASSWORD_VALIDATE_RESULT,
    CLEAR_REDUX,
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    result: null,
    error: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FORGOT_PASSWORD_VALIDATE:
            return { ...state, loading: true, error: null, result: null }

        case FORGOT_PASSWORD_VALIDATE_RESULT:
            return { ...state, loading: false, result: action.payload.result, error: action.payload.error }

        case CLEAR_REDUX:
            return ["", "FORGOT_PASSWORD"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
