import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter } from 'react-router-dom'

import store from './config/redux/store'
import { Provider } from 'react-redux'

import MainRouter from './containers/main'


import 'bootstrap/dist/css/bootstrap.css';
import 'assets/sass/main.scss';


const App = () => {
  return (
    <Fragment>
      <Provider store={store}>
        <BrowserRouter basename={'/'}>
          <Routes>
            <Route path='*' element={<MainRouter />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </Fragment>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
