//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging';

//action types
import {
    PQRS_CREATE
} from 'config/redux/actionTypes';

//actions
import {
    pqrsCreateResult
} from 'config/redux/actions';

const route = 'modules/main/redux/saga';

function* pqrsCreateRequest() {
    yield takeEvery(PQRS_CREATE, function* ({ payload }) {
        try {
            const { 
                name,
                lastname,
                documentType,
                document,
                city,
                phone,
                email,
                request,
                typeOfSale,
                subject,
                description,
                photo,
                files = []
            } = payload;

            let newFiles = [ ...files ];
            newFiles.unshift(photo);
            
            const response = yield call(
                clientMutation,
                `
                    mutation createPQRS(
                        $firstName: String, 
                        $lastName: String, 
                        $documentType: String, 
                        $document: String, 
                        $city: String,
                        $phone: String, 
                        $email: String, 
                        $request: String, 
                        $typeOfSale: String
                        $subject: String, 
                        $description: String, 
                        $files: [Upload]
                    ) {
                        createPQRS(
                            firstName: $firstName, 
                            lastName: $lastName, 
                            documentType: $documentType,
                            document: $document, 
                            city: $city,
                            phone: $phone, 
                            email: $email, 
                            request: $request,
                            typeOfSale: $typeOfSale
                            subject: $subject, 
                            description: $description, 
                            files: $files 
                        )
                    }
                `,
                {
                    name,
                    lastname,
                    documentType,
                    document,
                    city,
                    phone,
                    email,
                    request,
                    typeOfSale,
                    subject,
                    description,
                    files: newFiles
                },
                endpoints.GRAPHQL_GENERAL
            );

            if (!response?.createPQRS) {
                throw Error("Ha ocurrido un error!");
            }

            yield put(pqrsCreateResult(true, false));
        } catch (exc) {
            ExceptionManager(exc, route, 'pqrsCreateRequest');
            yield put(pqrsCreateResult(null, exc?.error || exc?.message));
        }
    });
}

//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(pqrsCreateRequest)
    ]);
}
