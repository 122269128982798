import {
    //TYPES
    GET_USER_PROFILE,
    GET_USER_PROFILE_RESULT,
    LOGOUT
} from 'config/redux/actionTypes';

export const getUserProfile = (navigate) => ({
    type: GET_USER_PROFILE,
    payload: { navigate },
});

export const getUserProfileResult = (data) => ({
    type: GET_USER_PROFILE_RESULT,
    payload: data,
});

export const logout = (navigate) => ({
    type: LOGOUT,
    payload: { navigate },
});