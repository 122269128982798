import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { endpoints, captcha } from 'config/constants';
import { Form, Input, Loading, Alert } from 'components';
import { IsMobile } from 'config/helpers/Utils';
import Modal from 'react-bootstrap/Modal';

import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

import {
    loginSingIn,
    loginValidate,
    clearRedux
} from 'config/redux/actions';

import './login.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const LoginView = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={captcha.REACT_APP_SITE_KEY}>
            {<LoginViewInternal />}
        </GoogleReCaptchaProvider>
    )
}

const LoginViewInternal = () => {
    const { messages } = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [showModal, setShowModal] = useState(true);

    const form = Form.useForm();

    const { loading, result, validateResult } = useSelector(state => state.loginRedux);
    const [errorMessage, setErrorMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const [username, setUsername] = useState();
    const [passwordType, setPasswordType] = useState(true);

    useEffect(() => {
        if (result) {
            if (result?.error?.error === "loginMaxAttemps") {
                setErrorMessage(messages['login_error_max_attempts']);
            } else {
                setErrorMessage(messages['login_error']);
            }

            dispatch(clearRedux("LOGIN", { result: null }));
        }
    }, [result]);

    useEffect(() => {
        if (validateResult) {
            if (validateResult?.code == 2) {
                setSuccessMessage(validateResult?.message);
                //dispatch(clearRedux("LOGIN", { validateResult: null }));
                setTimeout(() => {
                    navigate(`/updatePassword?document=${username}`);
                }, 2500);
            } else if (validateResult?.code == -1) {
                setErrorMessage(messages['login_signup_message']);
                dispatch(clearRedux("LOGIN", { validateResult: null }));
            } else if (validateResult?.code == 0) {
                //setErrorMessage(validateResult?.message || validateResult?.error);
                setErrorMessage(messages['errorMessage']);
                dispatch(clearRedux("LOGIN", { validateResult: null }));
            }
        }
    }, [validateResult]);


    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        const token = await executeRecaptcha('yourAction');
        // Do whatever you want with the token
        return token;
    }, [executeRecaptcha]);

    return (
        <div className="login">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-sm-12 col-md-9 col-lg-7">
                        <div className="login-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <div className="text-center mb-3">
                                            <img className="logo" src="./assets/images/credi10-white.png" alt="Credi10" />
                                        </div>
                                        <div className='bg-white form-container'>
                                            <Loading loading={loading}>
                                                <h2 className="text-center text-primary mb-4">
                                                    {messages["login_login"]}
                                                </h2>
                                                <Form
                                                    ref={form.ref}
                                                    onValidSubmit={async (values) => {
                                                        setErrorMessage();
                                                        setSuccessMessage();

                                                        const token = await handleReCaptchaVerify();
                                                        if (token) {
                                                            if (!validateResult) {
                                                                dispatch(loginValidate(values.username));
                                                                setUsername(values.username);
                                                            } else {
                                                                dispatch(loginSingIn(values.username, values.password, navigate));
                                                                setUsername(values.username);
                                                            }
                                                        } else {
                                                            setErrorMessage(messages['captchaErrorMessage']);
                                                        }
                                                    }}
                                                >
                                                    <div className="form-group">
                                                        <label className="mb-1"><strong>{messages["login_username"]}</strong></label>
                                                        <Form.Item
                                                            name="username"
                                                            rules={[
                                                                { required: true, message: messages["required_field"] },
                                                                { pattern: /^\d+$/, message: messages["login_username_placeholder"] },
                                                            ]}
                                                        >
                                                            <Input
                                                                placeholder={messages["login_username_placeholder"]}
                                                                className='input-rounded pe-5'
                                                                type="number"
                                                                autoComplete="off"
                                                                readOnly={(validateResult?.code == 1 || validateResult?.code == 2) ? true : false}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    {
                                                        validateResult?.code == 1 &&
                                                        <div className="form-group">
                                                            <label className="mb-1"><strong>{messages["login_password"]}</strong></label>
                                                            <Form.Item
                                                                name="password"
                                                                rules={[{ required: true, message: messages["required_field"] }]}
                                                            >
                                                                <Input
                                                                    placeholder={messages["login_password"]}
                                                                    className='input-rounded pe-5'
                                                                    autoComplete="off"
                                                                    type={passwordType ? "password" : "text"}
                                                                    icon={`${passwordType ? "fas fa-eye" : "fas fa-eye-slash"}`}
                                                                    onClickIcon={() => setPasswordType(!passwordType)}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    }

                                                    {
                                                        errorMessage &&
                                                        <div className="form-group">
                                                            <Alert type="danger" >
                                                                {errorMessage}
                                                            </Alert>
                                                        </div>
                                                    }

                                                    {
                                                        successMessage &&
                                                        <div className="form-group">
                                                            <Alert type="success" >
                                                                {successMessage}
                                                            </Alert>
                                                        </div>
                                                    }

                                                    <div className="form-row d-flex justify-content-between mt-4">
                                                        <div className="form-group mb-2">
                                                            <a href="/forgotPassword">
                                                                {messages["login_forgot_password"]}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        <button
                                                            className="btn btn-rounded btn-primary btn-block mb-3"
                                                            onClick={() => { form.submit() }}
                                                            disabled={validateResult?.code == 2 ? true : false}
                                                        >
                                                            {messages["continue"]}
                                                        </button>
                                                    </div>

                                                    <div className="text-center">
                                                        <a href={endpoints?.CREDI10_CLIENTS}>
                                                            <i className="fas fa-arrow-left me-2"></i>
                                                            {messages['login_back_home']}
                                                        </a>
                                                    </div>


                                                    {
                                                        !validateResult &&
                                                        <div className="row mt-4">
                                                            {
                                                                IsMobile() ?
                                                                    <div className="col-12">
                                                                        <a href={endpoints?.CREDI10_SIGNUP} className="text-primary" target="_blank">
                                                                            {messages["login_signup_mobile"]}
                                                                        </a>
                                                                    </div> :
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <span className='text-primary'>
                                                                                {messages["login_signup_web"]}
                                                                            </span>
                                                                        </div>
                                                                        <div className='text-center'>
                                                                            <img style={{ maxWidth: 120 }} className="" src="./assets/images/login/signup-qr.png" alt="Registrese" />
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                    }

                                                    {
                                                        validateResult &&
                                                        <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                                            <div
                                                                className='btn-link primary-text'
                                                                onClick={() => {
                                                                    dispatch(clearRedux("LOGIN", { validateResult: null }));
                                                                }}
                                                            >
                                                                <i className="fas fa-arrow-left me-2"></i>
                                                                <span>
                                                                    {messages["login_back"]}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                </Form>
                                            </Loading>
                                        </div>

                                        {/**
                                         * 
                                         * <div className="new-account mt-3">
                                            <a className="text-white cursor-pointer" href="#">{messages['login_register']}</a>
                                        </div>
                                         */}

                                        <div className="qr-flot">
                                            <div className="solicitar-flot">
                                                <a id="btn-registroqr" onClick={() => { setShowModal(true) }}><h3>Cómo cambiar contraseña</h3>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <span
                    className='fas fa-window-close'
                    style={{ position: 'absolute', top: -35, right: 10, fontSize: 20, padding: 10, backgroundColor: '#ffffff', cursor: 'pointer' }}
                    onClick={() => setShowModal(false)}
                ></span>
                <video width="100%" height="500px" controls>
                    <source src="/assets/videos/tutorial_crear_clave.mp4" type="video/mp4" />
                    Your browser does not support HTML video.
                </video>
            </Modal>
            <div>


            </div>
        </div>
    )
}

export default LoginView;