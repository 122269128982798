//imports
import { all } from 'redux-saga/effects';

import pqrsSagas from '../../pqrs/redux/sagas';

//merge sagas
export default function* rootSaga() {
    yield all([
        pqrsSagas(),
    ]);
}
