import { Routes, Route } from 'react-router-dom';

import StatementView from './list';

const StatementRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<StatementView />}></Route>
        </Routes>
    )
}

export default StatementRouter;