//imports
import { all } from 'redux-saga/effects';

import creditsListSagas from '../../list/redux/sagas';

//merge sagas
export default function* rootSaga() {
    yield all([
        creditsListSagas(),
    ]);
}
