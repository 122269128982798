//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging';
import moment from 'moment';

//action types
import {
    CREDITS_LIST_GET_CREDITS
} from 'config/redux/actionTypes';

//actions
import {
    creditsListGetCreditsResult
} from 'config/redux/actions';

const route = 'modules/main/redux/saga';

function* creditsListGetCreditsRequest() {
    yield takeEvery(CREDITS_LIST_GET_CREDITS, function* ({ payload }) {
        try {
            const { filters } = payload;
            const response = yield call(
                clientQuery,
                `query getLoans {
                    data: getLoans
                }`,
                {},
                endpoints.GRAPHQL_GENERAL
            );

            if (!response?.data) {
                throw Error("Ha ocurrido un error!");
            }

            let data = [];
            if (filters?.canceled) {
                data = response?.data?.filter(d => d?.Estado.toUpperCase() === "CANCELADO");
            } else {
                data = response?.data?.filter(d => d?.Estado.toUpperCase() != "CANCELADO");
            }

            let orderedData = [...data].sort((a, b) => {
                const itemA = moment(a?.FechaCompromiso).unix();
                const itemB = moment(b?.FechaCompromiso).unix();
        
                if (itemA > itemB) {
                    return -1;
                }
        
                if (itemA < itemB) {
                    return 1;
                }
        
                return 0;
            });

            yield put(creditsListGetCreditsResult(orderedData || [], false));
        } catch (exc) {
            ExceptionManager(exc, route, 'creditsListGetCreditsResult');
            yield put(creditsListGetCreditsResult([], true));
        }
    });
}

//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(creditsListGetCreditsRequest)
    ]);
}
