import cx from 'classnames'

export const Loading = ({ children, loading, className }) => {

    return (
        <div className={cx("", className)} style={{ position: "relative" }}>
            {
                loading &&
                <div className="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>
            }
            {children}
        </div>
    )
}