//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    LOGIN_LOGIN,
    LOGIN_VALIDATE,
} from 'config/redux/actionTypes';

//actions
import {
    loginSingInResult,
    loginValidateResult,
    clearRedux
} from 'config/redux/actions';

const route = 'security/views/login/redux/saga'


function* loginLoginRequest() {
    yield takeEvery(LOGIN_LOGIN, function* ({ payload }) {
        try {
            const { username, password, navigate } = payload;
            let data = yield call(
                clientMutation,
                `mutation login ($documentNumber: String, $password: String) {
                    login (documentNumber: $documentNumber, password: $password) {
                        token
                    }
                }`,
                {
                    documentNumber: username,
                    password
                },
                endpoints.GRAPHQL_AUTH,
                {}
            );

            if (data && data.login?.token) {
                localStorage.setItem("token", data.login.token)
                navigate('/');
                //yield put(loginSingInResult({ status: true }));
                yield put(clearRedux("LOGIN"));
            } else {
                throw Error("Incorrect Username or Password");
            }
        } catch (exc) {
            ExceptionManager(exc, route, 'loginLoginRequest');
            yield put(loginSingInResult({ status: false, error: exc }));

        }
    });
}

function* loginValidateRequest() {
    yield takeEvery(LOGIN_VALIDATE, function* ({ payload }) {
        try {
            const { username  } = payload;

            const response = yield call(
                clientMutation,
                `mutation loginValidate ($documentNumber: String) {
                    data: loginValidate (documentNumber: $documentNumber) {
                        code
                        message
                    }
                }`,
                {
                    documentNumber: username
                },
                endpoints.GRAPHQL_AUTH,
                {}
            );

            if (response && response.data) {
                yield put(loginValidateResult(response.data));
            } else {
                throw Error("Ha ocurrido un error, por favor intenta de nuevo");
            }
        } catch (exc) {
            ExceptionManager(exc, route, 'loginValidateRequest');
            yield put(loginValidateResult({ code: 0, error: exc?.message || exc?.error }));

        }
    });
}

// function* loginFacebookRequest() {
//     yield takeEvery(LOGIN_FACEBOOK, function* ({ payload }) {
//         try {
//             const { id, token, navigate } = payload;
//             let data = yield call(
//                 clientMutation,
//                 `mutation loginFacebook ($token: String, $id: String) {
//                     login: loginFacebook (token: $token, id: $id) {
//                         token
//                     }
//                 }`,
//                 {
//                     token,
//                     id
//                 },
//                 endpoints.GRAPHQL_AUTH,
//                 {}
//             );

//             console.log("data", data)
//             if (data && data.login?.token) {
//                 localStorage.setItem("token", data.login.token)
//                 navigate('/');
//                 //yield put(loginSingInResult({ status: true }));
//                 yield put(clearRedux("LOGIN"));
//             }
//             else
//                 throw Error("Incorrect Username or Password");

//         } catch (exc) {
//             ExceptionManager(exc, route, 'loginLoginRequest');
//             yield put(loginSingInResult({ status: false, error: exc }));

//         }
//     });
// }

// function* loginGoogleRequest() {
//     yield takeEvery(LOGIN_GOOGLE, function* ({ payload }) {
//         try {
//             const { id, token, navigate } = payload;
//             let data = yield call(
//                 clientMutation,
//                 `mutation loginGoogle ($token: String) {
//                     login: loginGoogle (token: $token) {
//                         token
//                     }
//                 }`,
//                 {
//                     token,
//                     id
//                 },
//                 endpoints.GRAPHQL_AUTH,
//                 {}
//             );

//             console.log("data", data)
//             if (data && data.login?.token) {
//                 localStorage.setItem("token", data.login.token)
//                 navigate('/');
//                 //yield put(loginSingInResult({ status: true }));
//                 yield put(clearRedux("LOGIN"));
//             }
//             else
//                 throw Error("Incorrect Username or Password");

//         } catch (exc) {
//             ExceptionManager(exc, route, 'loginLoginRequest');
//             yield put(loginSingInResult({ status: false, error: exc }));

//         }
//     });
// }



//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(loginLoginRequest),
        fork(loginValidateRequest),
        // fork(loginFacebookRequest),
        // fork(loginGoogleRequest)
    ]);
}
