import React, { Fragment, useEffect, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { endpoints } from 'config/constants';

import {
    Form,
    Input,
    Checkbox,
    Loading,
    InputFile,
    Select
} from 'components';

import { useLogManager } from 'config/hooks';
import { ShowErrorNotification, ShowSuccessNotification } from 'config/helpers/Utils';

import {
    pqrsCreate,
    clearRedux
} from 'config/redux/actions';


const PQRS = () => {
    const dispatch = useDispatch();
    const { messages } = useIntl();

    const form = Form.useForm();

    const { response, loading, error } = useSelector(state => state.pqrsRedux);

    useEffect(() => {
        return () => {
            dispatch(clearRedux("PQRS"));
        }
    }, []);

    useEffect(() => {
        if (error) {
            ShowErrorNotification(messages['pqrs_error_message']);
            dispatch(clearRedux("PQRS"));
        }
    }, [error]);

    useEffect(() => {
        if (response) {
            ShowSuccessNotification(messages['pqrs_success_message']);
            dispatch(clearRedux("PQRS"));
            form?.resetValues();
        }
    }, [response]);

    return (
        <Fragment>
            <Loading loading={loading}>
                <div className="container-fluid bg-white">
                    <div className="row">
                        <div className="col-sm-12 col-md-7">
                            <div className="card m-0">
                                <div className="card-header">
                                    <h3 className="fw-bold text-primary mb-3">
                                        {messages['pqrs_title']}
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <Form
                                            ref={form.ref}
                                            onValidSubmit={(values) => {
                                                dispatch(pqrsCreate(
                                                    values.name,
                                                    values.lastname,
                                                    values?.documentType?.value || "Cédula de ciudadanía",
                                                    values.document,
                                                    values.city,
                                                    values.phone,
                                                    values.email,
                                                    values?.request?.value || "",
                                                    values?.typeOfSale?.value || "",
                                                    values.subject,
                                                    values.description,
                                                    values?.photo || null,
                                                    values?.files || []
                                                ));
                                            }}
                                            loading={false}
                                        >
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Form.Item
                                                        name="name"
                                                        label={messages["pqrs_name"]}
                                                        rules={[{ required: true, message: messages['required_field'] }]}
                                                    >
                                                        <Input className='input-rounded pe-5' placeholder={messages["pqrs_name_placeholder"]} />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Form.Item
                                                        name="lastname"
                                                        label={messages["pqrs_lastname"]}
                                                        rules={[{ required: true, message: messages['required_field'] }]}
                                                    >
                                                        <Input className='input-rounded pe-5' placeholder={messages["pqrs_lastname_placeholder"]} />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Form.Item
                                                        name="documentType"
                                                        label={messages["pqrs_doument_type_label"]}
                                                        rules={[{ required: true, message: messages['required_field'] }]}
                                                    >
                                                        <Select
                                                            placeholder={messages["pqrs_select_placeholder"]}
                                                            options={[
                                                                { value: "Cédula de ciudadanía", label: "Cédula de ciudadanía"},
                                                                { value: "Cédula de extranjería", label: "Cédula de extranjería"},
                                                                { value: "Tarjeta de extranjería", label: "Tarjeta de extranjería"},
                                                                { value: "Tarjeta de identidad", label: "Tarjeta de identidad"},
                                                                { value: "NIT", label: "NIT"},
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Form.Item
                                                        name="document"
                                                        label={messages["pqrs_id"]}
                                                        rules={[{ required: true, message: messages['required_field'] }]}
                                                    >
                                                        <Input
                                                            className='input-rounded pe-5'
                                                            type="number"
                                                            placeholder={messages["pqrs_id_placeholder"]}
                                                            maxLength={12}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Form.Item
                                                        name="phone"
                                                        label={messages["pqrs_phone"]}
                                                        rules={[{ required: true, message: messages['required_field'] }]}
                                                    >
                                                        <Input 
                                                            className='input-rounded pe-5' 
                                                            type="number" 
                                                            placeholder={messages["pqrs_id_placeholder"]} 
                                                            maxLength={10}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Form.Item
                                                        name="city"
                                                        label={messages["pqrs_city"]}
                                                        rules={[{ required: true, message: messages['required_field'] }]}
                                                    >
                                                        <Input className='input-rounded pe-5' placeholder={messages["pqrs_city_placeholder"]} />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Form.Item
                                                        name="request"
                                                        label={messages["pqrs_request_label"]}
                                                        rules={[{ required: true, message: messages['required_field'] }]}
                                                    >
                                                        <Select
                                                            placeholder={messages["pqrs_select_placeholder"]}
                                                            options={[
                                                                { value: "Suplantación o fraude", label: "Suplantación o fraude"},
                                                                { value: "Anulación de mi crédito", label: "Anulación de mi crédito"},
                                                                { value: "Ajuste de mi crédito", label: "Ajuste de mi crédito"},
                                                                { value: "Retracto", label: "Retracto"}
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Form.Item
                                                        name="typeOfSale"
                                                        label={messages["pqrs_type_of_sale_label"]}
                                                        rules={[{ required: true, message: messages['required_field'] }]}
                                                    >
                                                        <Select
                                                            placeholder={messages["pqrs_select_placeholder"]}
                                                            options={[
                                                                { value: "Ecommerce", label: "Ecommerce"},
                                                                { value: "Tienda", label: "Tienda"}
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Form.Item
                                                        name="email"
                                                        label={messages["pqrs_email"]}
                                                        rules={[
                                                            { required: true, message: messages['required_field'] },
                                                            { email: true, message: messages['pqrs_email_error_message'] }
                                                        ]}
                                                    >
                                                        <Input className='input-rounded pe-5' type="email" placeholder={messages["pqrs_email"]} />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Form.Item
                                                        name="subject"
                                                        label={messages["pqrs_subject"]}
                                                        rules={[{ required: true, message: messages['required_field'] }]}
                                                    >
                                                        <Input
                                                            className='input-rounded pe-5'
                                                            type="text"
                                                            placeholder={messages["pqrs_subject_placeholder"]}
                                                            maxLength={100}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Form.Item
                                                        name="description"
                                                        label={messages["pqrs_description"]}
                                                        rules={[{ required: true, message: messages['required_field'] }]}
                                                    >
                                                        <Input
                                                            className='input-rounded pe-5'
                                                            type="textarea"
                                                            placeholder={messages["pqrs_description_placeholder"]}
                                                            maxLength={300}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Form.Item
                                                        name="photo"
                                                        label={messages["pqrs_photo_label"]}
                                                    >
                                                        <InputFile 
                                                            label={messages['pqrs_input_photo_label']}
                                                            description={messages['pqrs_file_types']}
                                                            accept=".png, .jpg, .jpeg"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Form.Item
                                                        name="files"
                                                        label={messages["pqrs_file_label"]}
                                                    >
                                                        <InputFile 
                                                            label={messages['pqrs_input_file_label']}
                                                            description={messages['pqrs_file_types']}
                                                            accept=".png, .jpg, .jpeg, .pdf"
                                                            limit={3}
                                                            multiple
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Form.Item
                                                        name="terms"
                                                        rules={[{ required: true, message: messages['required_field'] }]}
                                                    >
                                                        <Checkbox
                                                            id="terms"
                                                            label={messages["pqrs_terms_conditions"]}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Form>

                                        <div className="pt-4 pb-4 text-end">
                                            <button
                                                className="btn btn-sm btn-rounded btn-primary"
                                                onClick={() => {
                                                    form.submit();
                                                }}
                                                disabled={loading}
                                            >
                                                {messages["pqrs_send"]}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-5">
                            <iframe
                                src={`${endpoints?.CREDI10_CONTACTUS}`}
                                border={0}
                                title="Credi10 - Contáctenos"
                                width="100%"
                                height="100%"
                                style={{
                                    border: "0px",
                                    overflow: "hidden"
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Loading>
        </Fragment>
    )
}

export default PQRS;