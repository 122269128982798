export default {
    pqrs_title: "PQRS: Preguntas - Quejas - Reclamos - Solicitudes",
    pqrs_name: "Nombres",
    pqrs_name_placeholder: "Por favor escriba nombres completos",
    pqrs_lastname: "Apellidos",
    pqrs_lastname_placeholder: "Por favor escriba apellidos completos",
    pqrs_id: "Número de documento",
    pqrs_id_placeholder: "Por favor escriba sin comas, caracteres especiales o espacios",
    pqrs_phone: "Número de celular",
    pqrs_email: "Dirección de correo electrónico",
    pqrs_subject: "Asunto",
    pqrs_subject_placeholder: "Describa el problema en una frase",
    pqrs_files: "Archivos adjuntos",
    pqrs_upload: "Cargar",
    pqrs_cancel: "Cancelar",
    pqrs_send: "Enviar",
    pqrs_description: "Descripción",
    pqrs_description_placeholder: "Por favor escriba una descripción detallada del problema",
    pqrs_terms_conditions: "Acepto terminos y condiciones",
    pqrs_email_error_message: "Por favor escriba una dirección de correo eléctronico valida",
    pqrs_file_types: "Tipos de archivos permitidos",
    pqrs_photo_label: "Foto de la prenda",
    pqrs_file_label: "Documentos",
    pqrs_input_photo_label: "Haga click aquí para seleccionar una foto",
    pqrs_input_file_label: "Haga click aquí para seleccionar archivos",
    pqrs_doument_type_label: "Tipo de documento",
    pqrs_city: "Ciudad",
    pqrs_city_placeholder: "Ciudad",
    pqrs_request_label: "Solicitud",
    pqrs_request_placeholder: "Solicitud",
    pqrs_type_of_sale_label: "Tipo de venta",
    pqrs_type_of_sale_placeholder: "Tipo de venta",
    pqrs_select_placeholder: "Por favor selecciona una opción",
    pqrs_success_message: "Se ha enviado la solicitud exitosamente, gracias por sus comentarios",
    pqrs_error_message: "Ha ocurrido un error, por favor intenta de nuevo",
}