import React, { Fragment, useEffect, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
    Form,
    Input,
    TableView,
    Loading
} from 'components';

import { useLogManager } from 'config/hooks';
import { FormatNumberToCurrency, FormatDate, ShowErrorNotification } from 'config/helpers/Utils';
import {
    paymentsListGetPayments,
    clearRedux
} from 'config/redux/actions';

const PaymentsList = (props) => {
    const dispatch = useDispatch();
    const { messages } = useIntl();

    const tableView = TableView.useTableView();
    const logManager = useLogManager();
    const { data, loading, error } = useSelector(state => state.paymentsListRedux);

    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        loadData()
    }, []);

    useEffect(() => {
        if (error) {
            ShowErrorNotification(messages['errorMessage']);
            clearRedux("PAYMENTS_LIST", { error: false, response: [] });
        }
    }, [error]);

    const loadData = useCallback((filters) => {
        dispatch(paymentsListGetPayments(filters));
    });

    const columns = useMemo(() => [
        {
            title: messages["payment_list_id_document"],
            key: "Documento"
        },
        {
            title: messages["payment_list_number"],
            key: "Numero",
            headerStyle: {
                minWidth: 100
            },
            sortValue: (row) => {
                return parseInt(row?.Numero);
            }
        },
        {
            title: messages["payment_list_payment_date"],
            key: "FechaPago",
            headerStyle: {
                minWidth: 200
            },
            render: (row) => {
                return (
                    <div className='text-left'>
                        <span>{FormatDate(row?.FechaPago, "ll")}</span>
                    </div>
                );
            },
            exportValue: (row) => {
                return FormatDate(row?.FechaPago, "ll");
            },
            sortValue: (row) => {
                return moment(row?.FechaPago).unix();
            }
        },
        {
            title: messages["payment_list_payment_total"],
            key: "Valor",
            headerStyle: {
                minWidth: 100
            },
            headerClass: "text-center",
            render: (row) => {
                return (
                    <div className='text-right'>
                        <span>{FormatNumberToCurrency(row?.Valor || 0)}</span>
                    </div>
                );
            },
            exportValue: (row) => {
                return FormatNumberToCurrency(row?.Valor || 0);
            },
            sortValue: (row) => {
                return parseInt(row?.Valor);
            }
        },
        {
            title: messages["payment_list_brand"],
            key: "Marca"
        },
        {
            title: messages["payment_list_payment_place"],
            key: "PuntoVenta"
        },

    ]);

    return (
        <Fragment>
            <div className="container-fluid bg-white">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12">
                                <Loading loading={loading}>
                                    <div className="card m-0">
                                        <div className="card-header">
                                            <div className="row m-0 p-0 all-width">
                                                <div className="col-sm-12 col-md-6 m-0 p-0">
                                                    <h3 className="fw-bold text-primary mb-3">
                                                        {messages['payment_list_payments_made']}
                                                    </h3>
                                                </div>

                                                <div className="col-sm-12 col-md-6 m-0 p-0">
                                                    <div className='d-flex justify-content-xs-center justify-content-sm-center justify-content-md-end'>
                                                        <button
                                                            className="btn btn-xs btn-primary light btn-rounded me-2"
                                                            onClick={() => tableView.exportPdf()}
                                                        >
                                                            {messages['print']}
                                                        </button>

                                                        <Input
                                                            placeholder={messages['search']}
                                                            className="input-rounded pe-5"
                                                            icon="fas fa-search"
                                                            value={searchText}
                                                            onChange={(value) => {
                                                                setSearchText(value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <TableView
                                                    ref={tableView.ref}
                                                    height="calc(100vh - 370px)"
                                                    columns={columns}
                                                    items={data || []}
                                                    searchText={searchText}
                                                    name="Pagos-realizados"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Loading>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
};

export default PaymentsList;