import { all } from 'redux-saga/effects';

import containersSagas from 'containers/.config/redux/sagas';
import securitySagas from 'security/.config/redux/sagas';

import profileSagas from 'modules/profile/.config/redux/sagas';
import commonSagas from 'modules/common/.config/redux/sagas';
import mainSagas from 'modules/main/.config/redux/sagas';
import paymentsSagas from 'modules/payments/.config/redux/sagas';
import creditsSagas from 'modules/credits/.config/redux/sagas';
import pqrsSagas from 'modules/pqrs/.config/redux/sagas';
import statementSagas from 'modules/statement/.config/redux/sagas';


export default function* rootSaga() {
    yield all([
        containersSagas(),
        securitySagas(),
        commonSagas(),
        mainSagas(),
        paymentsSagas(),
        creditsSagas(),
        profileSagas(),
        pqrsSagas(),
        statementSagas()
    ]);
}