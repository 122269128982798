export default {
    "statement_list_title": "Estado de cuenta",
    "statement_list_name": "Nombre: ",
    "statement_list_totalQuota": "Cupo total: ",
    "statement_list_document": "Cédula: ",
    "statement_list_cutOffDate": "Fecha de corte: ",
    "statement_list_adress": "Dirección: ",
    "statement_list_city": "Ciudad: ",
    "statement_list_paymentDeadline": "Fecha limite de pago: ",
    "statement_list_quotaAvailable": "Cupo Disponible: ",
    "statement_list_fullPayment": "Pago total: ",
    "statement_list_minimumPayment": "Pago mínimo: ",
    "statement_list_summaryTotalPayment": "RESUMEN PAGO TOTAL",
    "statement_list_previousBalance": "Saldo Anterior",
    "statement_list_purchasesOfMonth": "+ Compras del mes",
    "statement_list_refinementsOfMont": "+ Refinaciones del mes",
    "statement_list_currentInterest": "+ Interes corriente",
    "statement_list_interestOnArrears": "+ Interes Mora",
    "statement_list_aval": "+ Aval",
    "statement_list_iva": "+ Iva",
    "statement_list_paymentsBonusesForTheMonth": "- Pagos/Abonos del mes",
    "statement_list_totalPayment": "= Pago Total",
    "statement_list_minimumPaymentSummary": "RESUMEN PAGO MINIMO",
    "statement_list_capital": "+ Capital",
    "statement_list_currentInteres": "+ Interes Corriente",
    "statement_list_purchaseDetail": "DETALLE DE COMPRAS",
    "statement_list_date": "Fecha",
    "statement_list_promissoryNote": "Pagaré",
    "statement_list_description": "Descripción",
    "statement_list_totalPurchase": "Total Compra",
    "statement_list_tate": "Tasa",
    "statement_list_totalQuotes": "Total Cuotas",
    "statement_list_pendingCosts": "Cuotas Pendientes",
    "statement_list_capitalTitle": "Capital",
    "statement_list_currentInteresTitle": "Interés corriente",
    "statement_list_avalTitle": "Aval",
    "statement_list_arrearsTitle": "Mora",
    "statement_list_ivaTitle": "Iva",
    "statement_list_totalQuote": "Total Cuota",
    "statement_list_paymentDetails": "DETALLE DE PAGOS",
    "statement_list_proofOfPayment": "Comprobante",
    "statement_list_totalPaymentTitle": "Total Pago",
    "statement_list_meanTitle": "Medio",
    "statement_list_download": "Descargar",
}

