import { Fragment, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { endpoints } from 'config/constants';

import cx from 'classnames';

import {
    changeThemeConfig
} from 'config/redux/actions';

const sidebar = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const { messages } = useIntl()

    const { user } = useSelector(state => state.authRedux);

    const getPathArray = (path) => {
        let pahtsArray = path.replace('/app/', '').split('/');
        let initialPaht = "/app"
        let paths = pahtsArray.map(x => {
            initialPaht = initialPaht + "/" + x
            return initialPaht
        })

        return paths
    }

    const [selectedItems, setSelectedItems] = useState(getPathArray(location.pathname))


    const items = [
        {
            label: messages['sidebar_dashboard'],
            icon: "fas fa-home",
            key: '/app/main',
        },
        {
            label: messages['sidebar_payments_made'],
            icon: "fas fa-money-bill-alt",
            key: '/app/payments',
        },
        {
            label: messages['sidebar_current_credits'],
            icon: "fa fa-credit-card",
            key: '/app/credits/current',
        },
        {
            label: messages['sidebar_canceled_credits'],
            icon: "fas fa-newspaper",
            key: '/app/credits/canceled',
        },
        {
            label: messages['sidebar_statement'],
            icon: "fas fa-file-invoice-dollar",
            key: '/app/statement',
        },
        {
            label: messages['sidebar_pqrs'],
            icon: "fas fa-headset",
            key: 'pqrs',
            url: endpoints?.CREDI10_PQRS
        }
    ];

    const getMenuItem = (item, isSubItem = false) => {
        let isSelected = selectedItems.find(x => x == item.key)

        return (
            <li key={item.key} className={cx(isSelected && "mm-active")}>
                <a
                    className={cx(item.children?.length && "has-arrow", !isSubItem && "ai-icon", isSelected && "mm-active")}
                    onClick={() => {
                        if (item.url) {
                            window.open(item.url, '_blank');
                        } else {
                            setSelectedItems(getPathArray(item.key));
                            if (!item.children?.length) {
                                navigate(item.key);
                                dispatch(changeThemeConfig({ isMenuActive: false }));
                            }
                        }
                    }}
                >
                    {
                        isSubItem
                            ?
                            item.label
                            :
                            <div className="text-left d-flex align-items-center justify-content-start">
                                <div style={{ width: 35 }} className="me-3">
                                    <span className={`${item?.icon}`}></span>
                                </div>
                                <span className="nav-text fs-5 mt-0">{item.label}</span>
                            </div>
                    }
                </a>

                {
                    isSelected &&
                    item.children?.length &&
                    <ul>
                        {
                            item.children.map(subItem => getMenuItem(subItem, true))
                        }
                    </ul>
                }
            </li>
        )
    }

    return (
        <Fragment>
            <div className="deznav">
                <div className="deznav-scroll">
                    <ul className="metismenu" id="menu">
                        {
                            items.map(item => getMenuItem(item))
                        }
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}

export default sidebar;