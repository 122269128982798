const debug = localStorage.getItem("debug") == "true" || false;
let url = process.env.URL_API || "https://credi10.ingeniosoft.net";
if (debug) {
    url = "http://localhost:9001";
}
    
export const endpoints = {
    GRAPHQL_AUTH: `${url}/api/auth`,
    GRAPHQL_GENERAL: `${url}/api/data`,
    CREDI10_SIGNUP: 'https://www.credi10.com.co/form_pro_consultados_inscripcion_web/',
    CREDI10_CONTACTUS: 'https://d-side.co/pruebas-dside/web-credi10/canales-de-servicio/',
    CREDI10_PAYMENT_LINK: 'https://credi10.com.co/',
    CREDI10_PQRS: 'https://credi10.zendesk.com/hc/es',
    CREDI10_CLIENTS: 'https://clientes.credi10.com.co/'
}

export const captcha = {
    REACT_APP_SITE_KEY: '6LeShQgoAAAAAGPXVzqxdnGqe4S9hKjShfDS4xwc'
}