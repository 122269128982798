//imports
import { all } from 'redux-saga/effects';

import dashboardSagas from '../../dashboard/redux/sagas';

//merge sagas
export default function* rootSaga() {
    yield all([
        dashboardSagas(),
    ]);
}
