import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Input, Loading, Alert } from 'components';
import { captcha } from 'config/constants';

import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

import {
    updatePasswordSave,
    loginSingIn,
    clearRedux
} from 'config/redux/actions';

import './styles.scss'

const UpdatePasswordView = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={captcha.REACT_APP_SITE_KEY}>
            {<UpdatePasswordViewInternal />}
        </GoogleReCaptchaProvider>
    )
}

const UpdatePasswordViewInternal = () => {
    const { messages } = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const form = Form.useForm();
    const { validateResult } = useSelector(state => state.loginRedux);
    const { loading, result, error } = useSelector(state => state.updatePasswordRedux);
    const { result: resultForgotPassword } = useSelector(state => state.forgotPasswordRedux);
    const [errorMessage, setErrorMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();

    const [searchParams, setSearchParams] = useSearchParams();
    let document = searchParams?.get('document');

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const [passwordType1, setPasswordType1] = useState(true);
    const [passwordType2, setPasswordType2] = useState(true);

    useEffect(() => {
        if (document && document != 0) {
            setTimeout(() => {
                //Is ref is taking time
                form?.setValues({ username: document });
            }, 500);
        }
    }, [document]);

    useEffect(() => {
        if (validateResult && validateResult?.code == 2) {
            if (validateResult?.code == 2) {
                setSuccessMessage(validateResult?.message);
            }

            dispatch(clearRedux("LOGIN", { validateResult: null }));
        }
    }, [validateResult]);

    useEffect(() => {
        if (resultForgotPassword) {
            setSuccessMessage(resultForgotPassword?.message || messages['forgot_password_error_message']);
            dispatch(clearRedux("FORGOT_PASSWORD", { result: null, error: null }));
        }
    }, [resultForgotPassword]);

    useEffect(() => {
        if (result) {
            setSuccessMessage(messages['update_password_success']);
            dispatch(clearRedux("UPDATE_PASSWORD", { result: null, error: null }));
            setTimeout(() => {
                dispatch(loginSingIn(username, password, navigate));
            }, 2000);
        }
    }, [result]);

    useEffect(() => {
        if (error) {
            setErrorMessage(messages['update_password_error']);
            dispatch(clearRedux("UPDATE_PASSWORD", { result: null, error: null }));
        }
    }, [error]);

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        const token = await executeRecaptcha('yourAction');
        // Do whatever you want with the token
        return token;
    }, [executeRecaptcha]);

    return (
        <div className="login h-100">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-sm-12 col-md-9 col-lg-7">
                        <div className="login-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <div className="text-center mb-3">
                                            <img className="logo" src="./assets/images/credi10-white.png" alt="Credi10" />
                                        </div>
                                        <div className='bg-white form-container'>
                                            <Loading loading={loading}>
                                                <h2 className="text-center text-primary mb-4">
                                                    {messages["update_password_title"]}
                                                </h2>
                                                <Form
                                                    ref={form.ref}
                                                    onValidSubmit={async (values) => {
                                                        setErrorMessage();
                                                        setSuccessMessage();

                                                        if (values?.password1 != values?.password2) {
                                                            setErrorMessage(messages['update_password_password_error']);
                                                            return;
                                                        }

                                                        const token = await handleReCaptchaVerify();
                                                        if (token) {
                                                            setUsername(values?.username);
                                                            setPassword(values?.password1);
                                                            dispatch(updatePasswordSave(values?.username?.trim(), values?.code?.trim(), values?.password1));
                                                        } else {
                                                            setErrorMessage(messages['captchaErrorMessage']);
                                                        }
                                                    }}
                                                >
                                                    <div className="form-group">
                                                        <label className="mb-1"><strong>{messages["update_password_id"]}</strong></label>
                                                        <Form.Item
                                                            name="username"
                                                            rules={[{ required: true, message: messages["required_field"] }]}
                                                        >
                                                            <Input
                                                                placeholder={messages["update_password_id"]}
                                                                className='input-rounded pe-5'
                                                                type="number"
                                                                autoComplete="off"
                                                                disabled={true}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="mb-1"><strong>{messages["update_password_code"]}</strong></label>
                                                        <Form.Item
                                                            name="code"
                                                            rules={[{ required: true, message: messages["required_field"] }]}
                                                        >
                                                            <Input
                                                                placeholder={messages["update_password_code_placeholder"]}
                                                                className='input-rounded pe-5'
                                                                type="text"
                                                                autoComplete="off"
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    {
                                                        errorMessage &&
                                                        <div className="form-group">
                                                            <Alert type="danger" >
                                                                {errorMessage}
                                                            </Alert>
                                                        </div>
                                                    }

                                                    {
                                                        successMessage &&
                                                        <div className="form-group">
                                                            <Alert type="success" >
                                                                {successMessage}
                                                            </Alert>
                                                        </div>
                                                    }

                                                    <div className="form-group">
                                                        <label className="mb-1"><strong>{messages["update_password_password1"]}</strong></label>
                                                        <Form.Item
                                                            name="password1"
                                                            rules={[
                                                                { required: true, message: messages["required_field"] },
                                                                { pattern: "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))", message: messages["update_password_password_error_strength"] },
                                                            ]}
                                                        >
                                                            <Input
                                                                placeholder={messages["update_password_password1"]}
                                                                className='input-rounded pe-5'
                                                                autoComplete="off"
                                                                type={passwordType1 ? "password" : "text"}
                                                                icon={`${passwordType1 ? "fas fa-eye" : "fas fa-eye-slash"}`}
                                                                onClickIcon={() => setPasswordType1(!passwordType1)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="mb-1"><strong>{messages["update_password_password2"]}</strong></label>
                                                        <Form.Item
                                                            name="password2"
                                                            rules={[
                                                                { required: true, message: messages["required_field"] },
                                                                { pattern: "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))", message: messages["update_password_password_error_strength"] },
                                                            ]}
                                                        >
                                                            <Input
                                                                placeholder={messages["update_password_password2"]}
                                                                className='input-rounded pe-5'
                                                                autoComplete="off"
                                                                type={passwordType2 ? "password" : "text"}
                                                                icon={`${passwordType2 ? "fas fa-eye" : "fas fa-eye-slash"}`}
                                                                onClickIcon={() => setPasswordType2(!passwordType2)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="text-center">
                                                        <button className="btn btn-rounded btn-primary btn-block mb-3" onClick={() => { form.submit() }}>
                                                            {messages["update_password_save"]}
                                                        </button>
                                                    </div>

                                                    <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                                        <a href="/login" className='primary-text'>
                                                            <i className="fas fa-arrow-left me-2"></i>
                                                            <span>
                                                                {messages["forgot_password_login"]}
                                                            </span>
                                                        </a>
                                                    </div>
                                                </Form>
                                            </Loading>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdatePasswordView;