import { Routes, Route } from 'react-router-dom';

import CreditsList from './list';

const CreditsRouter = () => {
    return (
        <Routes>
            <Route path='/canceled' element={<CreditsList canceled />}></Route>
            <Route path='/current' element={<CreditsList />}></Route>
        </Routes>
    )
}

export default CreditsRouter;