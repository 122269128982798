import { Fragment, useRef, useCallback } from "react";
import './style.scss'

export const InputFile = (props) => {
    const { 
        label, 
        value, 
        onChange, 
        multiple, 
        description, 
        accept, 
        limit, 
        loading, 
        disabled 
    } = props;

    const fileUploadRef = useRef();

    const getFile = useCallback((file) => {
        return (
            <div
                className="row justify-content-between file-item-wrapper"
            >
                <div className="col-8">
                    <div className="file-item">
                        {file?.file?.name}
                    </div>
                </div>
                <div className="col-4 text-right">
                    <span 
                        className="file-icon fas fa-times text-primary"
                        onClick={(e) => {
                            if (e?.preventDefault) e?.preventDefault();
                            let newValue = null;
                            if (multiple) {
                                newValue = value?.filter(nv => nv?.file?.name != file?.file?.name);
                            }
                            
                            onChange(newValue);
                        }}
                    >

                    </span>
                </div>
            </div>
        );
    });

    const getValues = useCallback(() => {
        if (multiple) {
            if (!value?.length) return;
            return (
                <div>
                    {
                        value?.map((v, i) => {
                            return <Fragment key={i}>{getFile(v)}</Fragment>
                        })
                    }
                </div>
            )
        } 

        if (!value?.file) return;
        return getFile(value);
    });

    return (
        <Fragment>
            <div
                className={`input-file-wrapper form-control input-rounded pe-5 ${disabled || (multiple && value?.length >= limit) ? "input-file-disabled " : ""}`}
                onClick={() => fileUploadRef?.current.click()}
            >
                <div 
                    className={`input-file-button pt-1 ${disabled || (multiple && value?.length >= limit) ? "text-disabled" : "text-primary"}`}
                >
                    {label}
                </div>

                <input
                    ref={fileUploadRef}
                    className="form-file-input form-control"
                    type="file"
                    accept={accept || ".png, .jpg, .jpeg, .pdf"}
                    disabled={disabled || (multiple && value?.length >= limit)}
                    onChange={(e) => {
                        var reader = new FileReader();
                        reader.onload = function () {
                            if (multiple) {
                                let newValue = []
                                
                                if (value && value?.length) newValue = [ ...value ];

                                newValue.push({ file: e.target.files[0] });
                                onChange(newValue);
                            } else {
                                onChange({ file: e.target.files[0] });
                            }
                        };

                        reader.readAsDataURL(e.target.files[0]);
                    }}
                />
            </div>

            {
                description &&
                <small 
                    className="text-muted pt-1 pb-2"
                    style={{
                        fontSize: 11
                    }}
                >
                    {`${description} ${accept}`}
                </small>
            }

            {getValues()}

        </Fragment>
    )
}