//Global keys
let locales = {
    
};

//Merge Modules
import creditsList from '../../list/lan/es'

locales = {
    ...creditsList
};

export default locales;