//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging';
import moment from 'moment';

//action types
import {
    STATEMENT_GET_STATEMENT
} from 'config/redux/actionTypes';

//actions
import {
    statementGetStatementResult
} from 'config/redux/actions';

const route = 'modules/payments/list/redux/saga';

function* statementGetStatementRequest() {
    yield takeEvery(STATEMENT_GET_STATEMENT, function* () {
        try {
            const response = yield call(
                clientQuery,
                `query getStatementAccount {
                    data: getStatementAccount
                }`,
                {},
                endpoints.GRAPHQL_GENERAL
            );

            if (!response?.data) {
                throw Error("Ha ocurrido un error!");
            }

            let data = response?.data || [];
           
            yield put(statementGetStatementResult(data || []));
        } catch (exc) {
            ExceptionManager(exc, route, 'statementGetStatementRequest');
            yield put(statementGetStatementResult([], true));
        }
    });
}

//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(statementGetStatementRequest)
    ]);
}
