//Global keys
let locales = {
    
};

//Merge Modules
import pqrs from '../../pqrs/lan/es'

locales = {
    ...pqrs
};

export default locales;