import {
    //TYPES
    LOGIN_LOGIN,
    LOGIN_FACEBOOK,
    LOGIN_GOOGLE,
    LOGIN_LOGIN_RESULT,
    LOGIN_VALIDATE,
    LOGIN_VALIDATE_RESULT,
    CLEAR_REDUX,
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    result: null,
    validateResult: null,
    error: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_VALIDATE:
            return { ...state, loading: true, validateResult: null }

        case LOGIN_VALIDATE_RESULT:
            return { ...state, loading: false, validateResult: action.payload.response }

        case LOGIN_LOGIN:
        case LOGIN_FACEBOOK:
        case LOGIN_GOOGLE:
            return { ...state, loading: true }

        case LOGIN_LOGIN_RESULT:
            return { ...state, loading: false, result: action.payload }

        case CLEAR_REDUX:
            return ["", "LOGIN"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
