import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'react-bootstrap/Modal';
import { Form, Input, Loading, Alert } from 'components'

import {
    profileOpenEditPassword,
    profileUpdatePassword,
    forgotPasswordValidate,
    clearRedux
} from 'config/redux/actions'

const ProfileUpdatePassword = (props) => {
    const dispatch = useDispatch();
    const { messages } = useIntl();

    const form = Form.useForm();
    const user = useSelector(state => state.authRedux.user);
    const { loading, open, result, error } = useSelector(state => state.profileUpdatePasswordRedux);
    const { loading: loadingValidate, result: resultValidate, error: errorValidate } = useSelector(state => state.forgotPasswordRedux);
    const [passwordType, setPasswordType] = useState(true);

    const [message, setMessage] = useState();

    useEffect(() => {
        return () => {
            setMessage();
            dispatch(clearRedux("PROFILE_UPDATE_PASSWORD"));
        }
    }, []);

    useEffect(() => {
        if (user?.username && open) {
            dispatch(forgotPasswordValidate(user?.username));
        }
    }, [user?.username, open]);

    useEffect(() => {
        if (resultValidate && open) {
            setMessage({ type: "success", message: messages['forgot_password_error_message'] });
            dispatch(clearRedux("FORGOT_PASSWORD", { result: null, error: null }));
        }
    }, [resultValidate, open]);

    useEffect(() => {
        if (errorValidate && open) {
            setMessage({ type: "danger", message: messages['forgot_password_error_message'] });
            dispatch(clearRedux("FORGOT_PASSWORD", { result: null, error: null }));
        }
    }, [errorValidate, open]);

    useEffect(() => {
        if (result && open) {
            setMessage({ type: "success", message: messages['update_password_success'] });
            dispatch(clearRedux("PROFILE_UPDATE_PASSWORD", { result: null, error: null }));
        }
    }, [result, open]);

    useEffect(() => {
        if (error && open) {
            setMessage({ type: "danger", message: messages['update_password_error'] });
            dispatch(clearRedux("PROFILE_UPDATE_PASSWORD", { result: null, error: null }));
        }
    }, [error, open]);

    const handleClose = useCallback(() => {
        form.resetValues();
        dispatch(profileOpenEditPassword());
    })

    return (
        <Modal
            show={open}
            onHide={handleClose}
            className="modal-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {messages["profile_update_password_title"]}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Loading
                    loading={loading || loadingValidate}
                >
                    <Form
                        ref={form.ref}
                        onValidSubmit={(values) => {
                            setMessage();
                            dispatch(profileUpdatePassword(user?.username, values?.code, values?.password1));
                        }}
                        loading={loading}
                    >
                        <div className="form-group">
                            <label className="mb-1"><strong>{messages["update_password_code"]}</strong></label>
                            <Form.Item
                                name="code"
                                rules={[{ required: true, message: messages["required_field"] }]}
                            >
                                <Input
                                    placeholder={messages["update_password_code"]}
                                    className='input-rounded pe-5'
                                    type="text"
                                    autoComplete="off"
                                />
                            </Form.Item>
                        </div>

                        <div className="form-group">
                            <label className="mb-1"><strong>{messages["update_password_password1"]}</strong></label>
                            <Form.Item
                                name="password1"
                                rules={[
                                    { required: true, message: messages["required_field"] },
                                    { pattern: "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))", message: messages["update_password_password_error_strength"] },
                                ]}
                            >
                                <Input
                                    placeholder={messages["update_password_password1"]}
                                    className='input-rounded pe-5'
                                    autoComplete="off"
                                    type={passwordType ? "password" : "text"} 
                                    icon={`${passwordType ? "fas fa-eye" : "fas fa-eye-slash"}`} 
                                    onClickIcon={() => setPasswordType(!passwordType)}
                                />
                            </Form.Item>
                        </div>

                        <div className="form-group">
                            <label className="mb-1"><strong>{messages["update_password_password2"]}</strong></label>
                            <Form.Item
                                name="password2"
                                rules={[
                                    { required: true, message: messages["required_field"] },
                                    { pattern: "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))", message: messages["update_password_password_error_strength"] },
                                ]}
                            >
                                <Input
                                    placeholder={messages["update_password_password2"]}
                                    className='input-rounded pe-5'
                                    autoComplete="off"
                                    type={passwordType ? "password" : "text"} 
                                    icon={`${passwordType ? "fas fa-eye" : "fas fa-eye-slash"}`} 
                                    onClickIcon={() => setPasswordType(!passwordType)}
                                />
                            </Form.Item>
                        </div>

                        {
                            message &&
                            <div className="form-group">
                                <Alert type={message?.type}>
                                    {message?.message}
                                </Alert>
                            </div>
                        }
                    </Form>
                </Loading>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-rounded btn-sm btn-default"
                    onClick={handleClose}
                    disabled={loading || loadingValidate}
                >
                    {messages["cancel"]}
                </button>

                <button
                    className="btn btn-rounded btn-sm btn-primary"
                    onClick={() => {
                        form.submit();
                    }}
                    disabled={loading || loadingValidate}
                >
                    {messages["save"]}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ProfileUpdatePassword;