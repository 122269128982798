import { Routes, Route } from 'react-router-dom';

import DashboardView from './dashboard';
const MainRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<DashboardView />}></Route>
        </Routes>
    )
}

export default MainRouter;