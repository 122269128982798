import {
    PQRS_CREATE,
    PQRS_CREATE_RESULT
} from 'config/redux/actionTypes';

export const pqrsCreate = (
    name,
    lastname,
    documentType,
    document,
    city,
    phone,
    email,
    request,
    typeOfSale,
    subject,
    description,
    photo,
    files
) => ({
    type: PQRS_CREATE,
    payload: { 
        name,
        lastname,
        documentType,
        document,
        city,
        phone,
        email,
        request,
        typeOfSale,
        subject,
        description,
        photo,
        files  
    }
});

export const pqrsCreateResult = (response, error) => ({
    type: PQRS_CREATE_RESULT,
    payload: { response, error },
});