import React, { useEffect, useState, useCallback } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { captcha } from 'config/constants';
import { Form, Input, Loading, Alert } from 'components';

import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

import {
    forgotPasswordValidate,
    clearRedux
} from 'config/redux/actions';

import './styles.scss'

const ForgotPasswordView = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={captcha.REACT_APP_SITE_KEY}>
            {<ForgotPasswordViewInternal />}
        </GoogleReCaptchaProvider>
    )
}

const ForgotPasswordViewInternal = () => {
    const { messages } = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const form = Form.useForm()
    const { loading, result, error } = useSelector(state => state.forgotPasswordRedux);
    const [errorMessage, setErrorMessage] = useState();

    const [username, setUsername] = useState();

    useEffect(() => {
        if (result) {
            navigate(`/updatePassword?document=${username}`);
        }
    }, [result]);

    useEffect(() => {
        if (error) {
            setErrorMessage(messages['forgot_password_error_message']);
            dispatch(clearRedux("FORGOT_PASSWORD", { result: null, error: null }));
        }
    }, [error]);

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        const token = await executeRecaptcha('yourAction');
        // Do whatever you want with the token
        return token;
    }, [executeRecaptcha]);

    return (
        <div className="login h-100">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-sm-12 col-md-9 col-lg-7">
                        <div className="login-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <div className="text-center mb-3">
                                            <img className="logo" src="./assets/images/credi10-white.png" alt="Credi10" />
                                        </div>
                                        <div className='bg-white form-container'>
                                            <Loading loading={loading}>
                                                <h2 className="text-center text-primary mb-4">
                                                    {messages["forgot_password_title"]}
                                                </h2>
                                                <Form
                                                    ref={form.ref}
                                                    onValidSubmit={async (values) => {
                                                        setErrorMessage();
                                                        setUsername(values.username);

                                                        const token = await handleReCaptchaVerify();
                                                        if (token) {
                                                            dispatch(forgotPasswordValidate(values.username));
                                                        } else {
                                                            setErrorMessage(messages['captchaErrorMessage']);
                                                        }
                                                    }}
                                                >
                                                    <div className="form-group">
                                                        <label className="mb-1"><strong>{messages["forgot_password_id"]}</strong></label>
                                                        <Form.Item
                                                            name="username"
                                                            rules={[
                                                                { required: true, message: messages["required_field"] },
                                                                { pattern: /^\d+$/, message: messages["login_username_placeholder"] }
                                                            ]}
                                                        >
                                                            <Input
                                                                placeholder={messages["login_username_placeholder"]}
                                                                className='input-rounded pe-5'
                                                                type="number"
                                                                autoComplete="off"
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    {
                                                        errorMessage &&
                                                        <div className="form-group">
                                                            <Alert type="success" >
                                                                {errorMessage}
                                                            </Alert>
                                                        </div>
                                                    }

                                                    <div className="text-center">
                                                        <button className="btn btn-rounded btn-primary btn-block mb-2" onClick={() => { form.submit() }}>
                                                            {messages["continue"]}
                                                        </button>
                                                    </div>


                                                    <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                                        <a href="/login" className='primary-text'>
                                                            <i className="fas fa-arrow-left me-2"></i>
                                                            <span>
                                                                {messages["forgot_password_login"]}
                                                            </span>
                                                        </a>
                                                    </div>
                                                </Form>
                                            </Loading>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordView;