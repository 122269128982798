import React, { Fragment, useEffect, useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
    Loading
} from 'components'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ArcElement,
    Legend,
} from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import { endpoints } from 'config/constants';

import { Pie, Bar, getElementAtEvent } from 'react-chartjs-2';
import moment from 'moment';
import { FormatNumberToCurrency, FormatDate, ShowErrorNotification } from 'config/helpers/Utils';
import { IsMobile } from 'config/helpers/Utils';

ChartJS.register(
    CategoryScale,
    ArcElement,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ChartDataLabels,
    Legend
);

import CreditsList from 'modules/credits/list';
import {
    mainDashboardGetDataPayments,
    mainDashboardGetLiquidation,
    clearRedux
} from 'config/redux/actions';

const Dashboard = (props) => {
    const barRef = useRef();
    const dispatch = useDispatch();
    const { messages } = useIntl();
    //Set momento to spanish for bar chart data
    moment.locale('es');

    const user = useSelector(state => state.authRedux.user);
    const { dataPayments, loadingPayments, loadingLiquidation, liquidationResult, error } = useSelector(state => state.dashboardRedux);

    const [barData, setBarData] = useState({
        labels: [],
        datasets: [],
        plugins: {
            title: {
                display: false,
            },
        },
        responsive: true
    });

    const [groupedByMonth, setGroupedByMonth] = useState(false);
    const [monthSelected, setMonthSelected] = useState();

    useEffect(() => {
        dispatch(mainDashboardGetDataPayments());
        dispatch(mainDashboardGetLiquidation());
    }, []);

    useEffect(() => {
        if (error) {
            ShowErrorNotification(messages['errorMessage']);
            clearRedux("MAIN_DASHBOARD", { error: false });
        }
    }, [error]);

    useEffect(() => {
        if (dataPayments) {
            getYearData(moment().subtract(12, 'months').format("YYYY"));
        }
    }, [dataPayments]);

    const getMonthNumber = useCallback(() => {
        return {
            "enero": 0,
            "febrero": 1,
            "marzo": 2,
            "abril": 3,
            "mayo": 4,
            "junio": 5,
            "julio": 6,
            "agosto": 7,
            "septiembre": 8,
            "octubre": 9,
            "noviembre": 10,
            "diciembre": 11,
        }
    });

    const getYearLabels = useCallback(() => {
        let labels = [];
        for (let i = 1; i < 13; i++) {
            labels.push(moment().subtract(12 - i, "months").format('MMMM'));
        }
        return labels;
    });

    const getYearData = useCallback((year) => {
        setGroupedByMonth(false);
        let labels = getYearLabels();
        
        let currentBarData = labels.map((label) => {
            const value = dataPayments?.reduce((accum, item) => {
                const paymentDate = moment(item.FechaPago);
                const index = getMonthNumber()[label];

                if (paymentDate.year() >= year && paymentDate.month() == index) {
                    return accum + Number(item.Valor || 0);
                } else {
                    return accum;
                }
            }, 0);

            return value
        });

        setBarData({
            ...barData,
            labels: labels,
            datasets: [
                {
                    label: "",
                    data: currentBarData,
                    backgroundColor: `rgba(222, 57, 128, 1)`,
                    datalabels: {
                        color: 'black',
                        display: false,
                        align: "top",
                        anchor: 'end',
                        font: {
                            size: IsMobile() ? "7px" : "12px",
                            weight: "bold"
                        },
                        formatter: (value) => {
                            return value ? FormatNumberToCurrency(value) : "";
                        }
                    },
                }
            ]
        });
    });

    const getMonthData = useCallback((month) => {
        setGroupedByMonth(true);
        let labels = [];
        let currentBarData = dataPayments?.filter(payment => {
            const paymentDate = moment(payment.FechaPago);
            if (paymentDate.month() == month) {
                return true;
            }

            return false;
        });

        currentBarData?.forEach(cb => {
            if (!labels?.includes(cb.FechaPago)) labels?.push(cb.FechaPago);
        });

        //Order month labels by date
        labels = labels?.sort((a, b) => {
            let newA = 0;
            let newB = 0;

            if (a) newA = moment(a).unix();
            if (b) newB = moment(b).unix();

            if (newA < newB) {
                return -1;
            }

            if (newA > newB) {
                return 1;
            }

            return 0;
        });

        //Order month data by date
        currentBarData = currentBarData?.sort((a, b) => {
            let newA = 0;
            let newB = 0;

            if (a.FechaPago) newA = moment(a.FechaPago).unix();
            if (b.FechaPago) newB = moment(b.FechaPago).unix();

            if (newA < newB) {
                return -1;
            }

            if (newA > newB) {
                return 1;
            }

            return 0;
        });

        currentBarData = labels.map((x, index) => {
            const value = currentBarData?.reduce((accum, item) => {
                if (item.FechaPago == x)
                    return accum + Number(item.Valor || 0);
                else
                    return accum;
            }, 0);

            return value;
        });

        setBarData({
            ...barData,
            month: month,
            labels: labels,
            datasets: [
                {
                    label: "",
                    data: currentBarData,
                    backgroundColor: `rgba(222, 57, 128, 1)`,
                    datalabels: {
                        color: 'white',
                        formatter: (value) => {
                            return FormatNumberToCurrency(value || 0);
                        }
                    },
                }
            ]
        });
    });

    const pieData = {
        labels: [messages['main_dashboard_available_quota'], messages['main_dashboard_credit']],
        datasets: [
            {
                label: '',
                type: 'doughnut',
                data: [user?.summary?.disponible, user?.summary?.saldo],
                datalabels: {
                    color: 'black',
                    display: true,
                    font: {
                        size: IsMobile() ? "7px" : "12px",
                        weight: "bold"
                    },
                    formatter: (value) => {
                        return value ? FormatNumberToCurrency(value) : "";
                    }
                },
                backgroundColor: [
                    'rgba(109, 222, 166, 1)',
                    'rgba(222, 57, 128, 1)'
                ],
                borderColor: [
                    'rgba(109, 222, 166, 1)',
                    'rgba(222, 57, 128, 1)'
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <Fragment>
            <div className="container-fluid bg-white">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            {
                                IsMobile() &&
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="card border-primary bg-primary">
                                        <div className="card-body">
                                            <div className="media align-items-center">
                                                <div className="media-body">
                                                    <a href={`${endpoints?.CREDI10_PAYMENT_LINK}`} target="_blank" >
                                                        <div className='d-flex justify-content-between'>
                                                            <p className="fs-22 mb-0 text-white fw-bold align-self-center">
                                                                {messages['header_pay_credit']}
                                                            </p>
                                                            <i className='fs-32 fas fa-money-bill-alt align-self-center text-white' />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="media align-items-center">
                                            <div className="media-body">
                                                <div className='d-flex justify-content-between mb-2'>
                                                    <p className="fs-22 mb-0 text-primary fw-bold align-self-center">
                                                        {messages['main_dashboard_total_to_pay']}
                                                    </p>
                                                    <a href={`${endpoints?.CREDI10_PAYMENT_LINK}`} target="_blank" className='cursor-pointer' >
                                                        <i className='fs-32 fas fa-money-bill-alt align-self-center' />
                                                    </a>
                                                </div>

                                                <h2 className="fs-36 text-black font-w600">
                                                    {FormatNumberToCurrency(liquidationResult?.pagototal || 0)}
                                                </h2>
                                                <span className="fs-13">
                                                    {messages['main_dashboard_cut_date']}: {FormatDate(liquidationResult?.fechacorteact, "DD/MMMM/YY")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <Loading loading={loadingLiquidation}>
                                            <div className="media align-items-center">
                                                <div className="media-body">
                                                    <div className='d-flex justify-content-between mb-2'>
                                                        <p className="fs-22 mb-0 text-primary fw-bold align-self-center">
                                                            {messages['main_dashboard_min_payment']}
                                                        </p>
                                                        <a href={`${endpoints?.CREDI10_PAYMENT_LINK}`} target="_blank" className='cursor-pointer' >
                                                            <i className='fs-32 fas fa-money-bill-alt align-self-center' />
                                                        </a>
                                                    </div>

                                                    <h2 className="fs-36 text-black font-w600">{FormatNumberToCurrency(liquidationResult?.pagopres || 0)}</h2>
                                                    <span className="fs-13">
                                                        {messages['main_dashboard_next_payment']}: {FormatDate(liquidationResult?.fechacorteactNew, "DD/MMMM/YY")}
                                                    </span>
                                                </div>
                                            </div>
                                        </Loading>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="media align-items-center">
                                            <div className="media-body">
                                                <div className='d-flex justify-content-between mb-2'>
                                                    <p className="fs-22 mb-0 text-primary fw-bold align-self-center">
                                                        {messages['main_dashboard_credicupo']}
                                                    </p>
                                                    <a href={`${endpoints?.CREDI10_PAYMENT_LINK}`} target="_blank" className='cursor-pointer' >
                                                        <i className='fs-32 fas fa-money-bill-alt align-self-center' />
                                                    </a>
                                                </div>

                                                <h2 className="fs-36 text-black font-w600">{FormatNumberToCurrency(user?.summary?.disponible || 0)}</h2>
                                                <span className="fs-13">{messages['main_dashboard_total']} {FormatNumberToCurrency(user?.summary?.totalcupo || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-8">
                                <div className="card">
                                    <div className="card-body">
                                        <div className='d-flex justify-content-between mb-2'>
                                            <a href="/app/payments" target="_blank" className='cursor-pointer'>
                                                <h3 className="fw-bold text-primary align-self-center">
                                                    {!groupedByMonth ? messages['main_dashboard_payments_year'] : `${messages['main_dashboard_payments_month']} ${monthSelected}`}
                                                </h3>
                                            </a>
                                            <a href="/app/payments" target="_blank" className='cursor-pointer'>
                                                <i className='fs-32 fas fa-calculator align-self-center' />
                                            </a>
                                        </div>

                                        <Loading loading={loadingPayments}>
                                            <Bar
                                                ref={barRef}
                                                options={{
                                                    responsive: true,
                                                    plugins: {
                                                        legend: {
                                                            position: 'top',
                                                            display: false
                                                        },
                                                        title: {
                                                            display: false,
                                                        }
                                                    }
                                                }}
                                                data={barData}
                                                onClick={(event) => {
                                                    if (!groupedByMonth) {
                                                        let item = getElementAtEvent(barRef?.current, event);

                                                        let month = null;
                                                        if (item?.length) {
                                                            let labels = getYearLabels();
                                                            let monthLabel = labels[item[0].index];
                                                            setMonthSelected(monthLabel);
                                                            month = getMonthNumber()[monthLabel];
                                                        }

                                                        if (month != null && month != undefined) {
                                                            getMonthData(month);
                                                        }
                                                    } else {
                                                        setMonthSelected();
                                                        getYearData(moment().subtract(12, 'months').format("YYYY"));
                                                    }
                                                }}
                                            />
                                        </Loading>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="fw-bold text-primary">
                                            {messages['main_dashboard_credicupo_available']}
                                        </h3>
                                        <Pie options={{ responsive: true }} data={pieData} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div 
                            className="row"
                            style={{
                                marginBottom: 30
                            }}
                        >
                            <div className="col-sm-12">
                                <CreditsList className="p-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment >
    )
}

export default Dashboard;