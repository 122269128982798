import {
    PAYMENTS_LIST_GET_PAYMENTS,
    PAYMENTS_LIST_GET_PAYMENTS_RESULT
} from 'config/redux/actionTypes';

export const paymentsListGetPayments = (filters) => ({
    type: PAYMENTS_LIST_GET_PAYMENTS,
    payload: { filters },
});

export const paymentsListGetPaymentsResult = (response, error) => ({
    type: PAYMENTS_LIST_GET_PAYMENTS_RESULT,
    payload: { response, error },
});