import { Routes, Route } from 'react-router-dom';

import PqrsView from './pqrs';
const PqrsRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<PqrsView />}></Route>
        </Routes>
    )
}

export default PqrsRouter;