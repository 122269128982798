import React from 'react'

import { useIntl, FormattedMessage } from 'react-intl';

//import './login.scss'
const htmProperties = { "lang": "en", "data-placement": "vertical", "data-behaviour": "pinned", "data-layout": "fluid", "data-radius": "flat", "data-color": "light-blue", "data-navcolo": "default", "data-show": "true" }

const LoginView = ({ history }) => {

    const { messages } = useIntl();
    const loading = false;

    //necesaro dejar los attributos basicos
    const html = document.querySelector('html');
    html.getAttributeNames().forEach(x => html.removeAttribute(x))
    Object.keys(htmProperties).forEach(key => {
        if (htmProperties[key])
            html.setAttribute(key, htmProperties[key])
        else
            html.removeAttribute(key)
    });

    return (
        <div className="h-100" >

            <div className="fixed-background"></div>

            <div className="container-fluid p-0 h-100 position-relative ">
                <div className="row g-0 h-100">

                    <div className="offset-0 col-12 d-none d-lg-flex offset-md-1 col-lg h-lg-100"></div>

                    <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0" style={{ backgroundColor: "#ffffff" }}>
                        <div className="sw-lg-80 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
                            <div className="sw-lg-60 px-5">
                                <div className="sh-11">
                                    <a href="index.html">
                                        <div className="logo-default"></div>
                                    </a>
                                </div>
                                <div className="mb-5">
                                    <h2 className="cta-1 mb-0 text-primary">Ooops, it looks like an error!</h2>
                                    <h2 className="display-2 text-primary">Error 404</h2>
                                </div>
                                <div className="mb-5">
                                    <p className="h6">It looks like the page you are looking for is not available.</p>
                                    <p className="h6">
                                        If you think that is a mistake, please
                                        <a href="index.html">contact</a>
                                        us.
                                    </p>
                                </div>
                                <div>
                                    <a className="btn btn-icon btn-icon-start btn-primary" onClick={() => { history.push('/app'); }}>
                                        <i data-cs-icon="arrow-left"></i>
                                        <span>Back to Home</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginView;