import React, { useState, Children, cloneElement, useRef, forwardRef, useImperativeHandle, useCallback, useEffect } from 'react';
import { isForwardRef, isElement } from 'react-is'
import cx from 'classnames'
import { useLogManager } from 'config/hooks'

export const FormContext = React.createContext();

export const Form = forwardRef(({ children, form, onSubmit, onValidSubmit, loading }, ref) => {

    const logManager = useLogManager()
    const [values, setValues] = useState({});
    const [invalidValues, setInvalidValues] = useState({});

    useImperativeHandle(ref, () => ({
        submit() {
            submit()
        },
        setValues(_values) {
            setValues(_values)
        },
        resetValues() {
            setValues({})
        },
        getValues() {
            return values;
        }
    }))


    const [childrenWithProps, refs] = addReferenceElements(children, useRef([]))

    const submit = useCallback(() => {

        setInvalidValues([])
        refs?.current?.forEach(x => x.validate && x.validate())

        onSubmit && onSubmit(values, invalidValues)

        logManager.console("Form", "submit", values, invalidValues)
        if (Object.keys(invalidValues).length == 0)
        onValidSubmit && onValidSubmit(values)
    })

    return (
        <FormContext.Provider value={{ values, setValues, invalidValues, setInvalidValues, loading }}>
            <div className="basic-form">
                {
                    loading &&
                    <div className="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                {/* <form> */}
                {childrenWithProps}
            </div>
        </FormContext.Provider>
    )
})


const addReferenceElements = (children, refs) => {


    const childrenWithProps = Children.map(children, (child, index) => {
      
        //console.log("child", child)
        let isPossibleOurElement = isElement(child) && isForwardRef(child);
        let props = {}

        if (isPossibleOurElement) {
            props.ref = (element) => { element && refs.current.push(element) }
            return cloneElement(child, props);
        }

        if (!isPossibleOurElement && child?.props?.children) {
            const [_childrenWithProps, _refs] = addReferenceElements(child?.props?.children, refs)
            props.children = _childrenWithProps
            refs = _refs
            return cloneElement(child, props);
        }

        return child

    });

    return [childrenWithProps, refs]

}

Form.Item = forwardRef(({ children, name, label, rules, className, placeholder, type }, ref) => {

    const { values, setValues, invalidValues, setInvalidValues, loading } = React.useContext(FormContext);
    const [errorMessage, setErrorMessage] = useState()
    const value = values[name]

    useImperativeHandle(ref, () => ({
        validate() {pattern
            isValid(value)
        }
    }))

    useEffect(() => {
        if (value == undefined) {
            values[name] = null
            setValues({ ...values })
        }
    }, [value])

    let required = rules?.find(x => x.required) || false;
    let pattern = rules?.find(x => x.pattern) || false;
    let email = rules?.find(x => x.email) || false;
    const isValid = useCallback((newValue) => {
        let result = true
        let newErrorMessage

        //required
        if (required && (!newValue || newValue == undefined || newValue?.length < 1)) {
            result = false
            newErrorMessage = rules?.find(x => x.required)?.message || 'Required Field';
        }

        //regex
        let regex = new RegExp(pattern?.pattern || "");
        if (pattern && newValue && !regex.test(newValue)) {
            result = false;
            newErrorMessage = rules?.find(x => x.pattern)?.message || 'Campo incorrecto';
        }

        //email;
        if (email && newValue && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(newValue?.trim())) {
            result = false;
            newErrorMessage = rules?.find(x => x.email)?.message || 'Campo incorrecto';
        }

        setErrorMessage(newErrorMessage)

        if (result) delete invalidValues[name]
        else invalidValues[name] = newValue

        setInvalidValues(invalidValues)
    })

    const onChange = useCallback((_value) => {
        values[name] = _value
        isValid(_value)
        setValues({
            ...values
        })
    })


    const childrenWithProps = Children.map(children, (child) => {
        return cloneElement(child, { 
            value: value, 
            onChange, 
            className: cx(child?.props?.className || "", errorMessage ? 'is-invalid' : ""), 
            loading,
            placeholder: child?.props?.placeholder || "", 
            type: child?.props?.type || "", 
            readOnly: child?.props?.readOnly || "", 
            disabled: child?.props?.disabled || "", 
            maxLength: child?.props?.maxLength || "",
            checked: value ? true : false, 
        });
    });

    return (
        <div className={cx("forItem mb-3 col", className)}>
            {label && <label className="form-label">{label} {" "} {required && <span className="text-danger">*</span>}</label>}
            {childrenWithProps}
            {errorMessage && <div className='invalid-feedback d-block'>{errorMessage}</div>}
        </div>
    )
})

Form.useForm = () => {

    let ref = useRef()

    return {
        ref: ref,
        setValues: (_values) => { ref?.current?.setValues(_values) },
        getValues: () => { ref?.current?.getValues() },
        resetValues: () => { ref?.current?.resetValues() },
        submit: () => { ref?.current?.submit() }
    }
}