//Global keys
let locales = {
    ok: 'Aceptar',
    or: 'o',
    close: 'Cerrar',
    add: 'Agregar',
    edit: 'Editar',
    delete: 'Eliminar',
    seeDetails: 'Ver Detalle',
    cancel: 'Cancelar',
    print: 'Imprimir',
    show: 'Ver',
    search: 'Buscar',
    continue: 'Continuar',
    back: 'Atras',
    save: "Guardar",
    new: "Nuevo",
    required_field: 'Campo requerido',
    clear: 'Limpiar',
    select: 'Selecccionar',
    rows: 'Registros',
    actions: 'Acciones',
    invalid_email: 'Email Invalido',
    or: "o",
    actions: "Acciones",
    refresh: "Refrescar",
    filter: "Filtrar",
    export: "Exportar",
    saveSuccessfully: "Se almaceno exitosamente!!",
    saveSuccessfullyTitle: "Bien!!",
    saveError: "Ocurrio un Error",
    selected: "Selecionado(s)",
    deleteSelected: "Eliminar Seleccionado(s)",
    continue: "Continuar",
    pleaseWaitLoading: "Please wait...",
    logout: "Cerrar Sesion",
    home: "Inicio",
    detail: "Detalle",
    table_page: "Página",
    table_of: "de",
    table_rows: "registros",
    export_to_pdf: "Exportar a PDF",
    accept: "Aceptar",
    errorMessage: "Ha ocurrido un error, por favor intenta de nuevo",
    captchaErrorMessage: "Por favor sigue las instrucciones del captcha"
};

//Merge Modules
import sidebar from 'containers/layouts/sidebar/lan/es';
import header from 'containers/layouts/header/lan/es';
import security from 'security/.config/lan/es';
import profile from 'modules/profile/.config/lan/es';
import main from 'modules/main/.config/lan/es';
import payments from 'modules/payments/.config/lan/es';
import credits from 'modules/credits/.config/lan/es';
import pqrs from 'modules/pqrs/.config/lan/es';
import statement from 'modules/statement/.config/lan/es';

locales = {
    ...locales,
    ...sidebar,
    ...header,
    ...security,
    ...profile,
    ...main,
    ...payments,
    ...credits,
    ...pqrs,
    ...statement
};

export default locales;