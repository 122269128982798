import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
    changeThemeConfig
} from 'config/redux/actions'

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        let resizeTimer = -1;
        function handleResize() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                setWindowSize({ width: window.innerWidth, height: window.innerHeight });
            }, 50);
        }
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            clearTimeout(resizeTimer);
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return windowSize;
};

export const useSetThemeValues = () => {
    const dispatch = useDispatch()

    const htmlTag = document.documentElement;
    const { width } = useWindowSize();
    const isMenuActive = useSelector(state => state.themeRedux.isMenuActive)

    htmlTag.setAttribute("data-theme-version", "light")
    htmlTag.setAttribute("data-layout", "vertical")
    htmlTag.setAttribute("data-nav-headerbg", "color_1")
    htmlTag.setAttribute("data-sidebar-position", "fixed")
    htmlTag.setAttribute("data-header-position", "fixed")
    htmlTag.setAttribute("data-container", "wide")
    htmlTag.setAttribute("direction", "ltr")
    htmlTag.setAttribute("data-primary", "color_1")
    htmlTag.setAttribute("data-typography", "poppins")

    useEffect(() => {
        if (width < 768) {
            htmlTag.setAttribute("data-sidebar-style", "overlay");
        }
        else if (width < 1200) {
            htmlTag.setAttribute("data-sidebar-style", "mini");
        }
        else {
            htmlTag.setAttribute("data-sidebar-style", "compact");
        }

        // if (isMenuActive)
        //     dispatch(changeThemeConfig({ isMenuActive: false }))

    }, [width])

}
