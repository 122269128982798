import cx from 'classnames'

export const Alert = (props) => {

    const { type, title, children } = props

    return (
        <div className={cx("alert", `alert-${type}`, "d-flex align-items-center")}>
            {/* <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">...</span> */}

            <div className="d-flex flex-column">
                {
                    title &&
                    <h4 className="mb-1 text-dark">
                        {title}
                    </h4>
                }
                {
                    children
                }
            </div>
        </div>
    )
}