//Global keys
let locales = {
    
};

//Merge Modules
import paymentsList from '../../list/lan/es'

locales = {
    ...paymentsList
};

export default locales;