import React, { Fragment, useEffect, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FormatNumberToCurrency, FormatToPercentage, ShowErrorNotification } from 'config/helpers/Utils';
import html2pdf from 'html2pdf.js';
import { Loading } from 'components';
import { clearRedux } from 'config/redux/actions';
import { useLogManager } from 'config/hooks';
import { statementGetStatement } from './redux/actions';



const StatementView = (props) => {
    const dispatch = useDispatch();
    const { messages } = useIntl();
    const { data, loading, error } = useSelector(state => state.statementReportRedux);
    const componentRef = useRef();
    const logManager = useLogManager();
    const options = {
        filename: `credi10_${data?.General?.Cedula}.pdf`,
        margin: [0.5, .5, 0.5, .5],
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' },
    };

    useEffect(() => {
        loadData()
    }, []);

    useEffect(() => {
        if (error) {
            ShowErrorNotification(messages['errorMessage']);
            clearRedux("STATEMENT_LIST", { error: false, response: [] });
        }
    }, [error]);

    const loadData = useCallback(() => {
        dispatch(statementGetStatement());
    });


    return (
        <Fragment>
            <div className="container-fluid bg-white">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12">
                                <Loading loading={loading}>
                                    <div className="card m-0" style={{ overflowX: 'scroll' }}>
                                        <div className="card-header">
                                            <div className="row m-0 p-0 all-width">
                                                <div className="col-sm-12 col-md-6 m-0 p-0">
                                                    <h3 className="fw-bold text-primary mb-3">
                                                        {messages['statement_list_title']}
                                                    </h3>
                                                </div>

                                                <div className="col-sm-12 col-md-6 m-0 p-0">
                                                    <div className='d-flex justify-content-xs-center justify-content-sm-center justify-content-md-end'>
                                                        {/* <ReactToPrint
                                                            trigger={() => <button className="btn btn-xs btn-primary light btn-rounded me-2"> {messages['print']} </button>}
                                                            content={() => componentRef.current}
                                                            copyStyles={true}
                                                            pageStyle="@page { size: portrait; } body { -webkit-print-color-adjust: exact !important; print-color-adjust: exact !important; }"
                                                        /> */}
                                                        <button className='btn btn-xs btn-primary light btn-rounded me-2' onClick={() => {
                                                            const content = componentRef.current;
                                                            html2pdf().set(options).from(content).save();
                                                        }}>
                                                            {messages['statement_list_download']}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body" style={{ minWidth: 700 }}>

                                            <div ref={componentRef} className='info-group g-0 ' style={{ margin: 20 }}>
                                                <div className='px-4'>
                                                    {/* Secccion imagen */}
                                                    <div className='image-report d-flex align-items-center justify-content-center'>
                                                        <img src="/assets/images/credi10.png" />
                                                    </div>
                                                    {/* fin seccion imagen */}
                                                    {/* primera seccion */}
                                                    <div className='col-12 row g-0'>
                                                        <div className='col-6'>
                                                            <div className=' d-flex align-items-center px-2 py-4'>
                                                                <label className='mr-3 lavel-pink-round' >{messages['statement_list_name']}</label>
                                                                <label className='lavel-round'>{data?.General?.Nombre}</label>
                                                            </div>
                                                            <div className='d-flex align-items-center px-2 py-4'>
                                                                <label className='mr-3 lavel-pink-round' >{messages['statement_list_document']}</label>
                                                                <label className='lavel-round'>{data?.General?.Cedula}</label>
                                                            </div>
                                                        </div>
                                                        <div className='col-6'>
                                                            <div className='d-flex align-items-center px-2 py-4'>
                                                                <label className='mr-3 lavel-pink-round'>{messages['statement_list_totalQuota']}</label>
                                                                <label className='lavel-round text-right '> {FormatNumberToCurrency(data?.General?.CupoTotal)} </label>
                                                            </div>
                                                            <div className='d-flex align-items-center px-2 py-4'>
                                                                <label className='mr-3 lavel-pink-round'>{messages['statement_list_cutOffDate']}</label>
                                                                <label className='lavel-round text-right '> {data?.General?.FechaCorte} </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Fin seccion */}
                                                    {/* segunda seccion  */}
                                                    <div className='col-12 row g-0'>
                                                        <div className='col-6 px-2'>
                                                            <div className='d-flex align-items-center lavel-round-gray px-3 py-1'>
                                                                <label className='mr-3 mr-label' >{messages['statement_list_adress'] + ' '}</label>
                                                                <label className=''> {data?.General?.Direccion}</label>
                                                            </div>
                                                            <div className='d-flex align-items-center  px-3 py-1'>
                                                                <label className='mr-3 mr-label st-custom-label' >{messages['statement_list_city']}</label>
                                                                <label className='st-custom-label'>{data?.General?.Ciudad}</label>
                                                            </div>
                                                            <div className='d-flex align-items-center lavel-round-gray px-3 py-1'>
                                                                <label className='mr-3 mr-label' >{messages['statement_list_paymentDeadline']}</label>
                                                                <label className=''>{data?.General?.FechaLimitePago}</label>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 px-2'>
                                                            <div className='d-flex align-items-center justify-content-between lavel-round-gray px-3 py-1'>
                                                                <label className='mr-3 mr-label' >{messages['statement_list_quotaAvailable']}</label>
                                                                <label className='text-right'> {FormatNumberToCurrency(data?.General?.CupoDisponible)}</label>
                                                            </div>
                                                            <div className='d-flex align-items-center justify-content-between px-3 py-1'>
                                                                <label className='mr-3 mr-label st-custom-label' >{messages['statement_list_fullPayment']}</label>
                                                                <label className='text-right st-custom-label'> {FormatNumberToCurrency(data?.General?.PagoTotal)}</label>
                                                            </div>
                                                            <div className='d-flex align-items-center justify-content-between lavel-round-gray px-3 py-1'>
                                                                <label className='mr-3 mr-label' >{messages['statement_list_minimumPayment']}</label>
                                                                <label className='text-right'> {FormatNumberToCurrency(data?.General?.PagoMinimo)}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* fin segunda seccion  */}
                                                    {/* tercera seccion  */}
                                                    <div className='col-12 row gy-3 gx-0 mt-2'>
                                                        <div className='col-6 p-0'>
                                                            <div className='title-pink text-center'>
                                                                <label> {messages['statement_list_summaryTotalPayment']} </label>
                                                            </div>
                                                            <div className='section-gray d-flex align-items-center justify-content-between'>
                                                                <label>  {messages['statement_list_previousBalance']} </label>
                                                                <label className='text-right'>{FormatNumberToCurrency(data?.ResumenPagoTotal?.SaldoAnterior)}</label>
                                                            </div>
                                                            <div className='section-white d-flex align-items-center justify-content-between'>
                                                                <label> {messages['statement_list_purchasesOfMonth']}</label>
                                                                <label className='text-right'> {FormatNumberToCurrency(data?.ResumenPagoTotal?.Compras)}</label>
                                                            </div>
                                                            <div className='section-gray d-flex align-items-center justify-content-between'>
                                                                <label> {messages['statement_list_refinementsOfMont']} </label>
                                                                <label className='text-right'> {FormatNumberToCurrency(data?.ResumenPagoTotal?.Refinanciaciones)}</label>
                                                            </div>
                                                            <div className='section-white d-flex align-items-center justify-content-between'>
                                                                <label> {messages['statement_list_currentInterest']} </label>
                                                                <label className='text-right '> {FormatNumberToCurrency(data?.ResumenPagoTotal?.InteresesCorrientes)}</label>
                                                            </div>
                                                            <div className='section-gray d-flex align-items-center justify-content-between'>
                                                                <label> {messages['statement_list_interestOnArrears']} </label>
                                                                <label className='text-right'> {FormatNumberToCurrency(data?.ResumenPagoTotal?.Mora)}</label>
                                                            </div>
                                                            <div className='section-white d-flex align-items-center justify-content-between'>
                                                                <label> {messages['statement_list_aval']} </label>
                                                                <label className='text-right'> {FormatNumberToCurrency(data?.ResumenPagoTotal?.Aval)}</label>
                                                            </div>
                                                            <div className='section-gray d-flex align-items-center justify-content-between'>
                                                                <label> {messages['statement_list_iva']} </label>
                                                                <label className='text-right'> {FormatNumberToCurrency(data?.ResumenPagoTotal?.Iva)} </label>
                                                            </div>
                                                            <div className='section-white d-flex align-items-center justify-content-between'>
                                                                <label> {messages['statement_list_paymentsBonusesForTheMonth']} </label>
                                                                <label className='text-right'> {FormatNumberToCurrency(data?.ResumenPagoTotal?.Abonos)} </label>
                                                            </div>
                                                            <div className='section-gray d-flex align-items-center justify-content-between'>
                                                                <label> {messages['statement_list_totalPayment']} </label>
                                                                <label className='text-right'> {FormatNumberToCurrency(data?.General?.PagoTotal)}  </label>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 p-0'>
                                                            <div className='title-pink text-center'>
                                                                <label> {messages['statement_list_minimumPaymentSummary']} </label>
                                                            </div>
                                                            <div className='section-gray d-flex align-items-center justify-content-between'>
                                                                <label>  {messages['statement_list_capital']} </label>
                                                                <label className='text-right d-flex align-items-center justify-content-between'> {FormatNumberToCurrency(data?.ResumenPagoMinimo?.Capital)} </label>
                                                            </div>
                                                            <div className='section-white d-flex align-items-center justify-content-between'>
                                                                <label> {messages['statement_list_currentInterest']} </label>
                                                                <label className='text-right'>  {FormatNumberToCurrency(data?.ResumenPagoMinimo?.InteresesCorrientes)} </label>
                                                            </div>
                                                            <div className='section-gray d-flex align-items-center justify-content-between'>
                                                                <label> {messages['statement_list_interestOnArrears']}  </label>
                                                                <label className='text-right'> {FormatNumberToCurrency(data?.ResumenPagoMinimo?.InteresesMora)}  </label>
                                                            </div>
                                                            <div className='section-white d-flex align-items-center justify-content-between'>
                                                                <label> {messages['statement_list_aval']} </label>
                                                                <label className='text-right'> {FormatNumberToCurrency(data?.ResumenPagoMinimo?.Aval)}  </label>
                                                            </div>
                                                            <div className='section-gray d-flex align-items-center justify-content-between'>
                                                                <label> {messages['statement_list_iva']} </label>
                                                                <label className='text-right'> {FormatNumberToCurrency(data?.ResumenPagoMinimo?.Iva)} </label>
                                                            </div>
                                                            <div className='section-white '>
                                                                <label>  </label>
                                                                <label> </label>
                                                            </div>
                                                            <div className='section-gray '>
                                                                <label>  </label>
                                                                <label>  </label>
                                                            </div>
                                                            <div className='section-white'>
                                                                <label>  </label>
                                                                <label>  </label>
                                                            </div>
                                                            <div className='section-gray d-flex align-items-center justify-content-between'>
                                                                <label> = {messages['statement_list_minimumPayment']} </label>
                                                                <label className='text-right'>{FormatNumberToCurrency(data?.General?.PagoMinimo)}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* fin tercera seccion  */}
                                                    {/* cuarta seccion  */}
                                                    <div className='d-flex justify-content-between row gy-3 gx-0 mt-2'>
                                                        <div className="section-title text-center">
                                                            {messages['statement_list_purchaseDetail']}
                                                        </div>
                                                        <div className='m-0 p-0 table-responsive'>
                                                            <table className="table m-0 table-statement table-sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">{messages['statement_list_date']}</th>
                                                                        <th scope="col">{messages['statement_list_promissoryNote']}</th>
                                                                        <th scope="col">{messages['statement_list_description']}</th>
                                                                        <th scope="col">{messages['statement_list_totalPurchase']}</th>
                                                                        <th scope="col">{messages['statement_list_tate']}</th>
                                                                        <th scope="col">{messages['statement_list_totalQuotes']}</th>
                                                                        <th scope="col">{messages['statement_list_pendingCosts']}</th>
                                                                        <th scope="col">{messages['statement_list_capitalTitle']}</th>
                                                                        <th scope="col">{messages['statement_list_currentInteresTitle']}</th>
                                                                        <th scope="col">{messages['statement_list_avalTitle']}</th>
                                                                        <th scope="col">{messages['statement_list_arrearsTitle']}</th>
                                                                        <th scope="col">{messages['statement_list_ivaTitle']}</th>
                                                                        <th scope="col">{messages['statement_list_totalQuote']}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {data?.DetalleCompras && data?.DetalleCompras.map((row, index) =>
                                                                    (
                                                                        <tr key={index} className={index % 2 === 0 ? 'table-row-white' : 'table-row-grey'}>
                                                                            <td scope="row">{row.Fecha}</td>
                                                                            <td scope="row">{row.Pagare}</td>
                                                                            <td scope="row">{row.Descripcion}</td>
                                                                            <td scope="row">{FormatNumberToCurrency(row.TotalCompra)}</td>
                                                                            <td scope="row">{FormatToPercentage(row.Tasa)}</td>
                                                                            <td scope="row">{row.TotalCuotas}</td>
                                                                            <td scope="row">{row.CuotasPendientes}</td>
                                                                            <td scope="row">{FormatNumberToCurrency(row.Capital)}</td>
                                                                            <td scope="row">{FormatNumberToCurrency(row.InteresesCorrientes)}</td>
                                                                            <td scope="row">{FormatNumberToCurrency(row.Aval)}</td>
                                                                            <td scope="row">{FormatNumberToCurrency(row.Mora)}</td>
                                                                            <td scope="row">{FormatNumberToCurrency(row.Iva)}</td>
                                                                            <td scope="row">{FormatNumberToCurrency(row.TotalCuota)}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    {/* fin cuarta seccion  */}
                                                    {/* quinta seccion  */}
                                                    <div className='d-flex justify-content-between row gy-3 gx-0 mt-2'>
                                                        <div className="section-title text-center">
                                                            {messages['statement_list_paymentDetails']}
                                                        </div>
                                                        <div className='m-0 p-0 table-responsive'>
                                                            <table className="table m-0 table-statement table-sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{messages['statement_list_date']}</th>
                                                                        <th>{messages['statement_list_proofOfPayment']}</th>
                                                                        <th> {messages['statement_list_description']}</th>
                                                                        <th>{messages['statement_list_meanTitle']}</th>
                                                                        <th>{messages['statement_list_capitalTitle']}</th>
                                                                        <th>{messages['statement_list_currentInteresTitle']}</th>
                                                                        <th>{messages['statement_list_avalTitle']}</th>
                                                                        <th>{messages['statement_list_arrearsTitle']}</th>
                                                                        <th>{messages['statement_list_ivaTitle']}</th>
                                                                        <th>{messages['statement_list_totalPaymentTitle']}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {data?.DetallePagos && data?.DetallePagos.map((row, index) =>
                                                                    (
                                                                        <tr key={index} className={index % 2 === 0 ? 'table-row-white' : 'table-row-grey'}>
                                                                            <td>{row.Fecha}</td>
                                                                            <td>{row.Comprobante}</td>
                                                                            <td>{row.Descripcion}</td>
                                                                            <td>{row.MedioPago}</td>
                                                                            <td>{FormatNumberToCurrency(row.Capital)}</td>
                                                                            <td>{FormatNumberToCurrency(row.InteresesCorrientes)}</td>
                                                                            <td>{FormatNumberToCurrency(row.Aval)}</td>
                                                                            <td>{FormatNumberToCurrency(row.Mora)}</td>
                                                                            <td>{FormatNumberToCurrency(row.Iva)}</td>
                                                                            <td>{FormatNumberToCurrency(row.TotalPago)}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* fin quinta seccion  */}
                                            </div>

                                        </div>
                                    </div>
                                </Loading>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
};

export default StatementView;