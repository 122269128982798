//imports
import { all } from 'redux-saga/effects';

import profileUpdatePasswordSagas from '../../components/updatePassword/redux/sagas';

//merge sagas
export default function* rootSaga() {
    yield all([
        profileUpdatePasswordSagas()
    ]);
}
