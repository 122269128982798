//imports
import { all } from 'redux-saga/effects';

import catalogSagas from '../../catalog/redux/sagas';

//merge sagas
export default function* rootSaga() {
    yield all([
        catalogSagas(),
    ]);
}
