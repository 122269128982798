import {
    MAIN_DASHBOARD_GET_DATA_PAYMENTS,
    MAIN_DASHBOARD_GET_DATA_PAYMENTS_RESULT,
    MAIN_DASHBOARD_GET_LIQUIDATION,
    MAIN_DASHBOARD_GET_LIQUIDATION_RESULT
} from 'config/redux/actionTypes';

export const mainDashboardGetDataPayments = (filters) => ({
    type: MAIN_DASHBOARD_GET_DATA_PAYMENTS,
    payload: { filters },
});

export const mainDashboardGetDataPaymentsResult = (response, error) => ({
    type:     MAIN_DASHBOARD_GET_DATA_PAYMENTS_RESULT,
    payload: { response, error },
});

export const mainDashboardGetLiquidation = () => ({
    type: MAIN_DASHBOARD_GET_LIQUIDATION,
    payload: {},
});

export const mainDashboardGetLiquidationResult = (response, error) => ({
    type:     MAIN_DASHBOARD_GET_LIQUIDATION_RESULT,
    payload: { response, error },
});