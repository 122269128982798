import loginRedux from '../../views/login/redux/reducer';
import forgotPasswordRedux from '../../views/forgotPassword/redux/reducer';
import updatePasswordRedux from '../../views/updatePassword/redux/reducer';

//export redux objects
export default {
	loginRedux,
	forgotPasswordRedux,
	updatePasswordRedux
};
