import {
    CREDITS_LIST_GET_CREDITS,
    CREDITS_LIST_GET_CREDITS_RESULT
} from 'config/redux/actionTypes';

export const creditsListGetCredits = (filters) => ({
    type: CREDITS_LIST_GET_CREDITS,
    payload: { filters },
});

export const creditsListGetCreditsResult = (response, error) => ({
    type: CREDITS_LIST_GET_CREDITS_RESULT,
    payload: { response, error },
});