import {
    CHANGE_THEME_CONFIG
} from 'config/redux/actionTypes';

const INIT_STATE = {
    lang: "en",
    isMenuActive: false 
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CHANGE_THEME_CONFIG:
            return { ...state, ...action.payload };

        default:
            return state;
    }
};
