import { combineReducers } from 'redux';

import themeRedux from './theme/reducer';
import containers from 'containers/.config/redux/reducers';
import security from 'security/.config/redux/reducers';

import common from 'modules/common/.config/redux/reducers'
import profile from 'modules/profile/.config/redux/reducers';
import main from 'modules/main/.config/redux/reducers';
import payments from 'modules/payments/.config/redux/reducers';
import credits from 'modules/credits/.config/redux/reducers';
import pqrs from 'modules/pqrs/.config/redux/reducers';
import statement from 'modules/statement/.config/redux/reducers';

const reducersModules = {
  themeRedux,
  ...containers,
  ...security,
  ...common,
  ...profile,
  ...main,
  ...payments,
  ...credits,
  ...pqrs,
  ...statement
};

const reducers = combineReducers(reducersModules);

export default reducers;