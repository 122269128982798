import {
    STATEMENT_GET_STATEMENT,
    STATEMENT_GET_STATEMENT_RESULT
} from 'config/redux/actionTypes';

export const statementGetStatement = () => ({
    type: STATEMENT_GET_STATEMENT,
});

export const statementGetStatementResult = (response, error) => ({
    type: STATEMENT_GET_STATEMENT_RESULT,
    payload: { response, error },
});