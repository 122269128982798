import ReactSelect from "react-select"
import cx from 'classnames'

export const Select = (props) => {
    const { options = [], className, isClearable = false, isSearchable = true, placeholder, onChange } = props

    return (
        <ReactSelect 
            options={options} 
            className={cx('react-select-container', className)} 
            classNamePrefix="react-select" 
            isClearable={isClearable} 
            isSearchable={isSearchable} 
            placeholder={placeholder || ""}
            onChange={(value) => {
                onChange(value)
            }}
            styles={{
                control: (baseStyles, state) => {
                    return {
                        ...baseStyles,
                        boxShadow: "0 0 0 0px #de3980",
                        borderColor: state.isFocused || state.menuIsOpen ? "#de3980" : 'black',
                        borderRadius: "6.25rem",
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 1,
                        paddingBottom: 1,
                        fontSize: "0.9rem",
                        "&:hover": { borderColor: '#de3980' }
                    }
                },
                option: (baseStyles, state) => {
                    return {
                        ...baseStyles,
                        backgroundColor: state.isSelected ? "#de3980" : "",
                        "&:hover": { 
                            backgroundColor: '#de3980',
                            color: "white"
                        },
                        "active": {
                            backgroundColor: '#de3980',
                            color: "white"
                        }
                    }
                }
            }}
        />
    )
}