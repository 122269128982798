export default {
    update_password_title: "Actualizar contraseña",
    update_password_id: "Número de cédula",
    update_password_code: "Código de seguridad",
    update_password_code_placeholder: "Ingresa código de seguridad",
    update_password_password1: "Nueva contraseña",
    update_password_password2: "Confirmar nueva contraseña",
    update_password_save: "Guardar",
    update_password_password_error: "Por favor valida la información, las contraseñas deben ser iguales",
    update_password_error: "Código inválido, por favor intenta de nuevo",
    update_password_password_error_strength: "La contraseña debe ser de mínimo 6 caracteres, contener una mayúscula, una minúscula, un número y un símbolo",
    update_password_success: "Se ha cambiado la contraseña exitosamente"
}