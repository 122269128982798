//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'
import moment from 'moment'

//action types
import {
    GET_CATALOG
} from 'config/redux/actionTypes';

//actions
import {
    getCatalogResult,
    catalogLoadigns
} from 'config/redux/actions';

const route = 'modules/common/catalogs/redux/saga'

const getCatalogInfo = (key, force, query, localData, querys) => {

    let getCatalog = true;
    let tempdata = sessionStorage.getItem('catalog_' + key);
    if (tempdata) tempdata = JSON.parse(tempdata);

    if (tempdata && tempdata.date && !force) {
        let date = moment(tempdata.date).add(60, 'seconds');
        if (date > moment()) {
            getCatalog = false;
        }
    }

    if (getCatalog) {
        querys.push(query);
    }
    else
        localData[key] = tempdata.items;

    return [querys, localData]
}


export function* getCagalogRequest() {
    yield takeEvery(GET_CATALOG, function* ({ payload }) {

        try {
            const { keys, force, params } = payload; //Flag force to update elements.
            let querys = [];
            let localData = {};
            let loadings = {};

            keys.forEach((key) => {
                switch (key) {
                    case 'roles':
                        loadings.loadingRoles = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                            roles: getRoles {
                                id
                                name
                                description
                            }`,
                            localData, querys)
                        break;

                    case 'users':
                        loadings.loadingRoles = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                                users: getUsers {
                                    id
                                    username
                                    fullName
                                    imageUrl
                                }`,
                            localData, querys)
                        break;
                }

            });

            yield put(catalogLoadigns(loadings));

            let finalQuery = 'query { ';
            querys.forEach((x) => {
                finalQuery += x;
            });
            finalQuery += ' }';

            let data = {};
            if (querys.length > 0)
                data = yield call(clientQuery, finalQuery, {}, endpoints.GRAPHQL_GENERAL);

            keys.forEach((key) => {
                if (data[key])
                    sessionStorage.setItem(
                        'catalog_' + key,
                        JSON.stringify({ date: moment(), items: data[key] }),
                    );
            });

            for (let i in loadings) {
                loadings[i] = false;
            }

            yield put(getCatalogResult({ ...data, ...localData, ...loadings }));
        } catch (exc) {
            // catch throw
            ExceptionManager(exc, route, 'getCagalogRequest');
        }
    });
}


//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(getCagalogRequest)
    ]);
}
