export default {
    "main_dashboard_total_to_pay": "Total a pagar",
    "main_dashboard_min_payment": "Pago mínimo",
    "main_dashboard_credicupo": "Tu Credicupo",
    "main_dashboard_payments_year": "Tus pagos realizados en los ultimos 12 meses",
    "main_dashboard_payments_month": "Tus pagos realizados en",
    "main_dashboard_credicupo_available": "Credicupo disponible",
    "main_dashboard_cut_date": "Fecha de corte",
    "main_dashboard_total": "Cupo total",
    "main_dashboard_available_quota": "Cupo Disponible",
    "main_dashboard_extra_quota": "Cupo Extra",
    "main_dashboard_credit": "Crédito",
    "main_dashboard_next_payment": "Fecha de pago"
}