export default {
    "credits_list_credit_number": "Pagaré",
    "credits_list_date_purchase": "Fecha compra",
    "credits_list_purchase_value": "Valor compra",
    "credits_list_purchase_balance": "Saldo",
    "credits_list_purchase_term": "Plazo",
    "credits_list_purchase_place": "Punto de venta",
    "credits_list_brand": "Marca",
    "credits_list_purchase_details": "Ver detalle",

    "credits_list_active_credits": "Tus créditos activos",
    "credits_list_canceled_credits": "Tus créditos cancelados",
}