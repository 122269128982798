//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging';
import moment from 'moment';

//action types
import {
    PAYMENTS_LIST_GET_PAYMENTS
} from 'config/redux/actionTypes';

//actions
import {
    paymentsListGetPaymentsResult
} from 'config/redux/actions';

const route = 'modules/payments/list/redux/saga';

function* paymentsListGetPaymentsRequest() {
    yield takeEvery(PAYMENTS_LIST_GET_PAYMENTS, function* ({ payload }) {
        try {
            const response = yield call(
                clientQuery,
                `query getPaymentsHistory {
                    data: getPaymentsHistory
                }`,
                {},
                endpoints.GRAPHQL_GENERAL
            );

            if (!response?.data) {
                throw Error("Ha ocurrido un error!");
            }

            let data = response?.data || [];
            let orderedData = [...data].sort((a, b) => {
                const itemA = moment(a?.FechaPago).unix();
                const itemB = moment(b?.FechaPago).unix();
        
                if (itemA > itemB) {
                    return -1;
                }
        
                if (itemA < itemB) {
                    return 1;
                }
        
                return 0;
            });

            yield put(paymentsListGetPaymentsResult(orderedData || []));
        } catch (exc) {
            ExceptionManager(exc, route, 'paymentsListGetPaymentsRequest');
            yield put(paymentsListGetPaymentsResult([], true));
        }
    });
}

//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(paymentsListGetPaymentsRequest)
    ]);
}
