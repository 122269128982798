import React, { Fragment, useEffect } from 'react';
import { IntlProvider } from 'react-intl'
import { Routes, Route, Navigate } from 'react-router-dom'


import { useSetThemeValues } from 'config/hooks/theme'

import locales from 'config/locales'
import LoginView from 'security/views/login'
import ForgotPasswordView from 'security/views/forgotPassword';
import UpdatePasswordView from 'security/views/updatePassword';
import ErrorView from 'security/views/error'
import ModulesRouter from 'modules'

const MainRouter = () => {

    useSetThemeValues();

    return (
        <IntlProvider locale='es' messages={locales['es']}>
            <Routes>
                <Route path='/' element={<Navigate to="/app" />} />
                <Route path='/app/*' element={<ModulesRouter />} />
                <Route path='/error' element={<ErrorView />} />
                <Route path='/login' element={<LoginView />} />
                <Route path='/forgotPassword' element={<ForgotPasswordView />} />
                <Route path='/updatePassword' element={<UpdatePasswordView />} />
                <Route path="*" element={<Navigate to="/error" />} />
            </Routes>
        </IntlProvider>

    )
}

export default MainRouter