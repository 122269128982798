//imports
import React, { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Loading } from 'components'

//Actions
import {
    getUserProfile
} from 'config/redux/actions';


//component
const AuthContainer = ({ children }) => {

    const dispatch = useDispatch()
    const { user, loading } = useSelector(state => state.authRedux);
    const navigate = useNavigate()

    const authCheck = useCallback(() => {
        let token = localStorage.getItem('token');

        if (!user && token) {
            dispatch(getUserProfile(navigate));
        } else {
            if (!token) navigate('/login');
        }
    })

    useEffect(() => {
        authCheck();
    }, [])

    return (
        <Fragment>
           <Loading loading={loading} className="h-100 bg-white">
            {
                user &&
                <Fragment>
                    {children}
                </Fragment>
            }
            </Loading>
        </Fragment>
    );

}

//Export Component
export default AuthContainer
