//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    FORGOT_PASSWORD_VALIDATE
} from 'config/redux/actionTypes';

//actions
import {
    forgotPasswordValidateResult
} from 'config/redux/actions';

const route = 'security/views/forgotPassword/redux/saga'

function* forgotPasswordRequest() {
    yield takeEvery(FORGOT_PASSWORD_VALIDATE, function* ({ payload }) {
        try {
            const { username } = payload;
            const response = yield call(
                clientMutation,
                `mutation changePasswordRequest($username: String) {
                    data: changePasswordRequest(username: $username){
                        code,
                        message
                    }
                }`,
                {
                    username: username
                },
                endpoints.GRAPHQL_AUTH,
                {}
            );

            if (response?.data) {
                yield put(forgotPasswordValidateResult(response?.data, false));
            } else {
                throw Error("Ha ocurrido un error, por favor intenta de nuevo");
            }
        } catch (exc) {
            ExceptionManager(exc, route, 'forgotPasswordRequest');
            yield put(forgotPasswordValidateResult(null, exc?.message || exc?.error));

        }
    });
}

//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(forgotPasswordRequest)
    ]);
}
