import {
    GET_CATALOG,
    GET_CATALOG_RESULT,
    CATALOG_LOADINGS,
    CLEAR_REDUX
} from 'config/redux/actionTypes';

const INIT_STATE = {
    loadingRoles: false,
    roles: [],
    loadingUsers: false,
    users: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_CATALOG:
            return state;

        case GET_CATALOG_RESULT:
            return { ...state, ...action.payload };

        case CATALOG_LOADINGS:
            return { ...state, ...action.payload };

        case CLEAR_REDUX:
            return ["", "COMMON_CATALOGS"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
