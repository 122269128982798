//Global keys
let locales = {
};

//Merge Modules
import login from '../../views/login/lan/es';
import forgotPassword from '../../views/forgotPassword/lan/es';
import updatePassword from '../../views/updatePassword/lan/es';

locales = {
    ...login,
    ...forgotPassword,
    ...updatePassword,
};

export default locales;