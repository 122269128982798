//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    PROFILE_UPDATE_PASSWORD
} from 'config/redux/actionTypes';

//actions
import {
    profileUpdatePasswordResult
} from 'config/redux/actions';

//route
const route = 'modules/profile/components/updatePassword/redux/saga'

function* profileUpdatePasswordRequest() {
    yield takeEvery(PROFILE_UPDATE_PASSWORD, function* ({ payload }) {
        try {
            const { username, code, password } = payload;
            const response = yield call(
                clientMutation,
                `mutation changePassword (
                    $username: String
                    $newPassword: String
                    $securityCode: String
                ) {
                    data: changePassword (
                        username: $username
                        newPassword: $newPassword
                        securityCode: $securityCode
                    ) {
                        code
                        message
                    }
                }`,
                {
                    username: username,
                    newPassword: password,
                    securityCode: code
                },
                endpoints.GRAPHQL_AUTH,
                {}
            );

            if (response?.data) {
                yield put(profileUpdatePasswordResult(response?.data, false));
            } else {
                throw Error("Ha ocurrido un error, por favor intenta de nuevo");
            }
        } catch (exc) {
            ExceptionManager(exc, route, 'profileUpdatePasswordRequest');
            yield put(profileUpdatePasswordResult(null, exc?.error || exc?.message));
        }
    });
}

//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(profileUpdatePasswordRequest)
    ]);
}
