import moment from 'moment';
import 'moment/min/locales';
import Swal from 'sweetalert2';

export const ValidateRoles = (roles, user) => {
    let result = false;
    if (!roles)
        result = true;
    else
        roles.forEach(x => {
            if (user.roles.find(r => r.id == x))
                result = true
        })

    return result
}


export const OpenFile = (data) => {

    if (typeof data === 'string')
        window.open(data, '_blank');
    else {
        if (navigator.userAgent.match('CriOS')) {
            let fileData = [data];
            var reader = new FileReader();
            var blob = new Blob(fileData, { type: data.type });
            reader.onload = function (e) {
                window.location.href = reader.result;
            }
            reader.readAsDataURL(blob);
        }
        // for IE and edge browser
        else if (window.navigator.msSaveOrOpenBlob) {
            let fileData = [data];
            let blobObject = new Blob(fileData);
            window.navigator.msSaveBlob(blobObject);
        }
        //for all other browser
        else {
            let fileData = [data];
            var blob = new Blob(fileData, { type: data.type });
            let url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        }
    }
}

export const FormatNumberToCurrency = (amount) => {
    const options = {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    };

    const numberFormat = new Intl.NumberFormat('es-CO', options);
    return numberFormat?.format(amount || 0)?.replace(/\s/g, '');
}

export const FormatDate = (date, format) => {
    if (!date) return "";
    moment.locale('es');
    return moment(date).format(format || "lll");
}

export const FormatToPercentage = (value) => {
    return (value).toFixed(2) + '%';
}

export const IsMobile = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
    }

    return false;
}

export const ShowErrorNotification = (message) => {
    Swal.fire({
        title: '',
        text: message,
        icon: 'error',
        confirmButtonText: "Aceptar",
        customClass: {
            confirmButton: "btn btn-md btn-rounded btn-primary",
        },
        iconColor: "#de3980",
        confirmButtonColor: "#de3980",
    });
}

export const ShowSuccessNotification = (message) => {
    Swal.fire({
        title: '',
        text: message,
        icon: 'success',
        confirmButtonText: "Aceptar",
        customClass: {
            confirmButton: "btn btn-md btn-rounded btn-primary",
        },
        iconColor: "#de3980",
        confirmButtonColor: "#de3980",
    });
}

export const OrderData = (list, key) => {
    let newSortList = [...list].sort((a, b) => {
        const itemA = String(a[key]).toUpperCase();
        const itemB = String(b[key]).toUpperCase();

        if (itemA < itemB) {
            return -1;
        }

        if (itemA > itemB) {
            return 1;
        }

        return 0;
    });

    return newSortList;
};