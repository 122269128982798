import moment from "moment";
import { Fragment } from "react";

const Footer = () => {

    return (
        <Fragment></Fragment>
    )
}

export default Footer;