import {
    PROFILE_OPEN_CLOSE_UPDATE_PASSWORD,
    PROFILE_UPDATE_PASSWORD,
    PROFILE_UPDATE_PASSWORD_RESULT
} from 'config/redux/actionTypes';

export const profileOpenEditPassword = (open) => ({
    type: PROFILE_OPEN_CLOSE_UPDATE_PASSWORD,
    payload: { open },
});

export const profileUpdatePassword = (username, code, password) => ({
    type: PROFILE_UPDATE_PASSWORD,
    payload: { username, code, password },
});

export const profileUpdatePasswordResult = (result, error) => ({
    type: PROFILE_UPDATE_PASSWORD_RESULT,
    payload: { result, error },
});