//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    GET_USER_PROFILE,
    LOGOUT
} from 'config/redux/actionTypes';

//actions
import {
    getUserProfileResult,
    logout,
    clearRedux
} from 'config/redux/actions';


function* authGetUserProfileRequest() {
    yield takeEvery(GET_USER_PROFILE, function* ({ payload }) {
        const { navigate } = payload;
        try {
            let data = yield call(
                clientQuery,
                `{
                    User: getUserProfile {
                        id
                        email
                        username
                        name
                        summary
                    }
                }`,
                {},
                endpoints.GRAPHQL_GENERAL,
            );

            if (data && data.User) {

                localStorage.setItem("username", data.User.username)
                yield put(getUserProfileResult(data.User));
            }
            else
                throw 'not user';

        } catch (exc) {
            ExceptionManager(exc, 'containers/auth/redux/saga', 'authGetUserProfileRequest');
            yield put(logout(navigate));
            yield put(getUserProfileResult());

        }
    });
}

function* authLogoutRequest() {
    yield takeEvery(LOGOUT, function* ({ payload }) {
        const { navigate } = payload;

        try {
            yield put(clearRedux(""));
            localStorage.removeItem('token');
            let keys = Object.keys(sessionStorage)
            keys.forEach(key => {
                sessionStorage.removeItem(key)
            })
            console.log('logout');
            // normal logout
            navigate('/login');

        } catch (exc) {

            ExceptionManager(exc, 'containers/auth/redux/saga', 'authLogoutRequest');
            // catch throw
            if (navigate)
                navigate('/login');
        }
    }
    );
}


//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(authGetUserProfileRequest),
        fork(authLogoutRequest)
    ]);
}
