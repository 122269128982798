//Global keys
let locales = {
    
};

//Merge Modules
import dashboard from '../../dashboard/lan/es'

locales = {
    ...dashboard
};

export default locales;