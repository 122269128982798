//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    UPDATE_PASSWORD_SAVE
} from 'config/redux/actionTypes';

//actions
import {
    updatePasswordSaveResult
} from 'config/redux/actions';

const route = 'security/views/updatePassword/redux/saga'

function* updatePasswordRequest() {
    yield takeEvery(UPDATE_PASSWORD_SAVE, function* ({ payload }) {
        try {
            const { username, code, password } = payload;
            const response = yield call(
                clientMutation,
                `mutation changePassword (
                    $username: String
                    $newPassword: String
                    $securityCode: String
                ) {
                    data: changePassword (
                        username: $username
                        newPassword: $newPassword
                        securityCode: $securityCode
                    ) {
                        code
                        message
                    }
                }`,
                {
                    username: username,
                    newPassword: password,
                    securityCode: code
                },
                endpoints.GRAPHQL_AUTH,
                {}
            );

            if (response?.data) {
                yield put(updatePasswordSaveResult(response?.data, false));
            } else {
                throw Error("Ha ocurrido un error, por favor intenta de nuevo");
            }
        } catch (exc) {
            ExceptionManager(exc, route, 'updatePasswordRequest');
            yield put(updatePasswordSaveResult(null, exc?.message || exc?.error));
        }
    });
}

//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(updatePasswordRequest)
    ]);
}
