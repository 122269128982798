import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames'

import AutContainer from 'containers/auth'

import Header from 'containers/layouts/header'
import Footer from 'containers/layouts/footer'
import Sidebar from '../containers/layouts/sidebar'

import MainRouter from './main';
import PaymentsRouter from './payments';
import CreditsRouter from './credits';
import PqrsRouter from './pqrs';
import StatementRouter from './statement';

const ModulesRouter = () => {
    const { user } = useSelector(state => state.authRedux)
    const isMenuActive = useSelector(state => state.themeRedux.isMenuActive)

    return (
        <AutContainer history={history}>
            <div id="main-wrapper" className={cx('show', isMenuActive && "menu-toggle")}>
                <Header />
                <Sidebar />
                <div className="content-body h-100 bg-white">

                    {
                        user &&
                        <Routes>
                            <Route path='/' element={<Navigate to="/app/main" />}></Route>
                            <Route path='main' element={<MainRouter />} />
                            <Route path='payments' element={<PaymentsRouter />} />
                            <Route path='credits/*' element={<CreditsRouter />} />
                            <Route path='pqrs/*' element={<PqrsRouter />} />
                            <Route path='statement/*' element={<StatementRouter />} />
                            <Route path="*" element={<Navigate to="/error" />} />
                        </Routes>

                    }
                </div>
                <Footer />
            </div>
        </AutContainer>
    )
}

export default ModulesRouter;