import { Routes, Route } from 'react-router-dom';

import PaymentsList from './list';

const PaymentsRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<PaymentsList />}></Route>
        </Routes>
    )
}

export default PaymentsRouter;