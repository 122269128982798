import {
    //TYPES
    FORGOT_PASSWORD_VALIDATE,
    FORGOT_PASSWORD_VALIDATE_RESULT
} from 'config/redux/actionTypes';

export const forgotPasswordValidate = (username) => ({
    type: FORGOT_PASSWORD_VALIDATE,
    payload: { username },
});

export const forgotPasswordValidateResult = (result, error) => ({
    type: FORGOT_PASSWORD_VALIDATE_RESULT,
    payload: { result, error },
});