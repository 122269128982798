let locales = {
    login_login: "Iniciar sesión",
    login_forgot_password: '¿Olvidaste tu contraseña?',
    login_username: 'Número de cédula',
    login_password: 'Contraseña',
    login_register: '¿No tiene una cuenta? Registrese aquí',
    login_singin: 'Iniciar',
    login_with_facebook: 'Continue con Facebook',
    login_with_google: 'Continue con Google',
    login_error: 'Cédula o contraseña incorrecta',
    login_signup_web: '¿Deseas adquirir un crédito? Escanea el QR y regístrate',
    login_signup_mobile: '¿Deseas adquirir un crédito? Haz click aquí y regístrate',
    login_signup_message: '¿Deseas adquirir un crédito? regístrate',
    login_username_placeholder: "Solo números",
    login_back: "Regresar",
    login_error_max_attempts: "Has alcanzado el límite de intentos, por favor intenta de nuevo dentro de 1 minuto",
    login_back_home: "Regresar a inicio"
};

export default locales;