//Global keys
let locales = {
    
};

//Merge Modules
import updatePassword from '../../components/updatePassword/lan/es'

locales = {
    ...updatePassword
};

export default locales;